import { QueryObserverOptions, useQuery } from 'react-query';
import { CTokenId } from 'types';

import getCTokenInterestRateModel, {
  GetCTokenInterestRateModelOutput,
} from 'clients/api/queries/getCTokenInterestRateModel';
import { useCTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetCTokenInterestRateModelOutput,
  Error,
  GetCTokenInterestRateModelOutput,
  GetCTokenInterestRateModelOutput,
  [FunctionKey.GET_V_TOKEN_INTEREST_RATE_MODEL, CTokenId]
>;

const useGetCTokenInterestRateModel = ({ cTokenId }: { cTokenId: CTokenId }, options?: Options) => {
  const cTokenContract = useCTokenContract(cTokenId);

  return useQuery(
    [FunctionKey.GET_V_TOKEN_INTEREST_RATE_MODEL, cTokenId],
    () => getCTokenInterestRateModel({ cTokenContract }),
    options,
  );
};

export default useGetCTokenInterestRateModel;
