import * as React from 'react';
import { SVGProps } from 'react';

const SvgVote = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.1668 9.03333H0.833508C0.566841 9.03333 0.333508 8.86666 0.233508 8.63333C0.133508 8.4 0.200175 8.1 0.366841 7.93333L7.53351 0.866666C7.80017 0.633332 8.20017 0.633332 8.43351 0.866666L15.6002 7.93333C15.8002 8.13333 15.8335 8.4 15.7335 8.63333C15.7002 8.86666 15.4335 9.03333 15.1668 9.03333ZM2.40017 7.73333H13.5668L8.00017 2.23333L2.40017 7.73333Z"
      fill="currentColor"
    />
    <path
      d="M15.1667 16.3333H0.833415C0.466748 16.3333 0.166748 16.0333 0.166748 15.6667C0.166748 15.3 0.466748 15 0.833415 15H15.2001C15.5667 15 15.8667 15.3 15.8667 15.6667C15.8334 16.0333 15.5334 16.3333 15.1667 16.3333Z"
      fill="currentColor"
    />
    <path
      d="M2.13346 16.3333C1.7668 16.3333 1.4668 16.0333 1.4668 15.6667V8.4C1.4668 8.03333 1.7668 7.73333 2.13346 7.73333C2.50013 7.73333 2.80013 8.03333 2.80013 8.4V15.7C2.80013 16.0333 2.50013 16.3333 2.13346 16.3333Z"
      fill="currentColor"
    />
    <path
      d="M8.00016 16.3333C7.6335 16.3333 7.3335 16.0333 7.3335 15.6667V8.4C7.3335 8.03333 7.6335 7.73333 8.00016 7.73333C8.36683 7.73333 8.66683 8.03333 8.66683 8.4V15.7C8.66683 16.0333 8.36683 16.3333 8.00016 16.3333Z"
      fill="currentColor"
    />
    <path
      d="M13.8669 16.3333C13.5002 16.3333 13.2002 16.0333 13.2002 15.6667V8.4C13.2002 8.03333 13.5002 7.73333 13.8669 7.73333C14.2335 7.73333 14.5335 8.03333 14.5335 8.4V15.7C14.5002 16.0333 14.2335 16.3333 13.8669 16.3333Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgVote;
