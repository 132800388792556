import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    maxStyle: css`
      background-color: rgba(43, 209, 129, 0.1);
      color: rgba(43, 209, 129, 1);
      border: none;
      padding: ${theme.spacing(1.5)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)};
      :hover {
        background-color: rgba(43, 209, 129, 0.2) !important;
      }
    `,
  };
};
