import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    theme,
    container: css`
      margin: ${theme.spacing(0, 10)};

      ${theme.breakpoints.down('md')} {
        margin: ${theme.spacing(-2, 4, 0)};
      }
    `,
    walletList: css`
      margin-bottom: ${theme.spacing(8)};
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: ${theme.spacing(6)};
      align-items: start;

      ${theme.breakpoints.down('md')} {
        grid-template-columns: 1fr;
        row-gap: ${theme.spacing(2)};
        column-gap: ${theme.spacing(0)};
        margin-bottom: ${theme.spacing(4)};
      }
    `,
    getListItem: ({ isChosen }: { isChosen: boolean }) => css`
      background-color: #070707;
      box-shadow: none;
      aspect-ratio: 1;
      border: 1px solid #242424;
      border-radius: ${theme.shape.borderRadius.small}px;
      padding: ${theme.spacing(5)} ${theme.spacing(0)};
      color: ${theme.palette.text.primary};
      text-align: center;
      cursor: pointer;
      background-color: ${theme.palette.background.paper};
      :hover {
        background-color: ${theme.palette.background.default};
      }
      
      ${theme.breakpoints.down('md')} {
        aspect-ratio: auto;
        display: flex;
        align-items: center;
      }

      ${isChosen &&
      css`
        border: 1px solid ${theme.palette.button.main};
      `}
    `,
    walletLogo: css`
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      margin: ${theme.spacing(0, 'auto', 1)};
      display: block;
      
      ${theme.breakpoints.down('md')} {
        margin: ${theme.spacing(0, 1)};
        width: ${theme.spacing(10)};
        margin-left: ${theme.spacing(5)};
      }
    `,
    walletLabel: css`
    ${theme.breakpoints.down('md')} {
      font-size: 20px;
      margin: 0px 10px;
    }
    `,
    comingSoonText: css`
      color: ${theme.palette.text.secondary};
    `,
    footer: css`
      text-align: center;
      padding: ${theme.spacing(0, 4)};
      margin: 15px 0px;
    `,
    footerLink: css`
      color: ${theme.palette.button.main};

      :hover {
        color: ${theme.palette.button.medium};
      }

      :active {
        color: ${theme.palette.button.dark};
      }
    `,
  };
};
