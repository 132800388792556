import { TokenId, CTokenId } from 'types';
import { getContractAddress, getToken, getCErcToken } from 'utilities';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { getWeb3NoAccount } from 'clients/web3';
import erc20Abi from 'constants/contracts/abis/erc20.json';
import comptrollerAbi from 'constants/contracts/abis/comptroller.json';
import governorBravoDelegateAbi from 'constants/contracts/abis/governorBravoDelegate.json';
import interestModelAbi from 'constants/contracts/abis/interestModel.json';
import maximillionAbi from 'constants/contracts/abis/maximillion.json';
import oracleAbi from 'constants/contracts/abis/oracle.json';
import cErc20Abi from 'constants/contracts/abis/cErc20.json';
import cBttTokenAbi from 'constants/contracts/abis/cBttToken.json';
// import vaiTokenAbi from 'constants/contracts/abis/vaiToken.json';
import unitrollerAbi from 'constants/contracts/abis/unitroller.json';
// import vaiVaultAbi from 'constants/contracts/abis/vaiVault.json';
import venusLensAbi from 'constants/contracts/abis/venusLens.json';
// import vrtConverterAbi from 'constants/contracts/abis/vrtConverter.json';
// import vrtTokenAbi from 'constants/contracts/abis/vrtToken.json';
// import vrtVaultAbi from 'constants/contracts/abis/vrtVault.json';
// import xvsTokenAbi from 'constants/contracts/abis/xvsToken.json';
import blendTokenAbi from 'constants/contracts/abis/blendToken.json';
// import xvsVaultAbi from 'constants/contracts/abis/xvsVault.json';
// import xvsVaultStoreAbi from 'constants/contracts/abis/xvsVaultStore.json';
// import xvsVestingAbi from 'constants/contracts/abis/xvsVesting.json';
import {
  Erc20,
  Comptroller,
  GovernorBravoDelegate,
  InterestModel,
  Maximillion,
  Oracle,
  Unitroller,
  BlendToken,
  // VaiVault,
  VenusLens,
  // VrtConverter,
  // VrtVault,
  // BlendToken,
  // XvsVaultStore,
  // XvsVesting,
} from 'types/contracts';

import { TokenContract, CTokenContract } from './types';

const getContract = <T>(abi: AbiItem | AbiItem[], address: string, web3Instance: Web3) => {
  const web3 = web3Instance ?? getWeb3NoAccount();
  return new web3.eth.Contract(abi, address) as unknown as T;
};

export const getTokenContract = <T extends TokenId>(tokenId: T, web3: Web3): TokenContract<T> => {
  const tokenAddress = getToken(tokenId).address;

  if (tokenId === 'blend') {
    return getContract<TokenContract<T>>(blendTokenAbi as AbiItem[], tokenAddress, web3);
  }

  // if (tokenId === 'vai') {
  //   return getContract<TokenContract<T>>(vaiTokenAbi as AbiItem[], tokenAddress, web3);
  // }

  // if (tokenId === 'vrt') {
  //   return getContract<TokenContract<T>>(vrtTokenAbi as AbiItem[], tokenAddress, web3);
  // }

  return getContract<TokenContract<T>>(erc20Abi as AbiItem[], tokenAddress, web3);
};

export const getTokenContractByAddress = (address: string, web3: Web3): Erc20 =>
  getContract(erc20Abi as AbiItem[], address, web3) as unknown as Erc20;

export const getCTokenContract = <T extends CTokenId>(
  tokenId: T,
  web3: Web3,
): CTokenContract<T> => {
  const cErcTokenAddress = getCErcToken(tokenId).address;
  // @ts-ignore (coz MARKET is not deployed)
  if (tokenId === 'btt') {
    return getContract(
      cBttTokenAbi as AbiItem[],
      cErcTokenAddress,
      web3,
    ) as unknown as CTokenContract<T>;
  }

  return getContract(
    cErc20Abi as AbiItem[],
    cErcTokenAddress,
    web3,
  ) as unknown as CTokenContract<T>;
};

export const getUnitrollerContract = (web3: Web3) =>
  getContract(
    unitrollerAbi as AbiItem[],
    getContractAddress('unitroller'),
    web3,
  ) as unknown as Unitroller;

// export const getVaiVaultContract = (web3: Web3) =>
//   getContract(
//     vaiVaultAbi as AbiItem[],
//     getContractAddress('vaiVault'),
//     web3,
//   ) as unknown as VaiVault;

// export const getXvsVaultContract = (web3: Web3) =>
//   getContract(
//     xvsVaultAbi as AbiItem[],
//     getContractAddress('xvsVault'),
//     web3,
//   ) as unknown as BlendToken;

export const getBlendProxyContract = (web3: Web3) =>
  getContract(
    blendTokenAbi as AbiItem[],
    getContractAddress('blend'),
    web3,
  ) as unknown as BlendToken;

// export const getXvsVaultStoreContract = (web3: Web3) =>
//   getContract(
//     xvsVaultStoreAbi as AbiItem[],
//     getContractAddress('xvsVaultStore'),
//     web3,
//   ) as unknown as XvsVaultStore;

export const getComptrollerContract = (web3: Web3) =>
  getContract(
    comptrollerAbi as AbiItem[],
    getContractAddress('comptroller'),
    web3,
  ) as unknown as Comptroller;

export const getPriceOracleContract = (web3: Web3) =>
  getContract(oracleAbi as AbiItem[], getContractAddress('oracle'), web3) as unknown as Oracle;

export const getInterestModelContract = (address: string, web3: Web3) =>
  getContract(interestModelAbi as AbiItem[], address, web3) as unknown as InterestModel;

export const getVenusLensContract = (web3: Web3) =>
  getContract(
    venusLensAbi as AbiItem[],
    getContractAddress('venusLens'),
    web3,
  ) as unknown as VenusLens;

export const getGovernorBravoDelegateContract = (web3: Web3) =>
  getContract(
    governorBravoDelegateAbi as AbiItem[],
    getContractAddress('governorBravoDelegator'),
    web3,
  ) as unknown as GovernorBravoDelegate;

export const getMaximillionContract = (web3: Web3) =>
  getContract(
    maximillionAbi as AbiItem[],
    getContractAddress('maximillion'),
    web3,
  ) as unknown as Maximillion;

// // VRT conversion
// export const getXvsVestingProxyContract = (web3: Web3) =>
//   getContract(
//     xvsVestingAbi as AbiItem[],
//     getContractAddress('xvsVestingProxy'),
//     web3,
//   ) as unknown as XvsVesting;

// export const getVrtConverterProxyContract = (web3: Web3) =>
//   getContract(
//     vrtConverterAbi as AbiItem[],
//     getContractAddress('vrtConverterProxy'),
//     web3,
//   ) as unknown as VrtConverter;

// // VRT vault
// export const getVrtVaultProxyContract = (web3: Web3) =>
//   getContract(
//     vrtVaultAbi as AbiItem[],
//     getContractAddress('vrtVaultProxy'),
//     web3,
//   ) as unknown as VrtVault;
