import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    root: (checked: boolean) =>
      css`
        padding: 0;

        svg {
          height: ${theme.spacing(6)};
          width: ${theme.spacing(6)};
          color: ${checked ? theme.palette.interactive.primary : 'none'};
        }

        :hover {
          svg {
            color: ${theme.palette.interactive.primary};
          }
        }
      `,
  };
};

export default useStyles;
