import * as React from 'react';
import { SVGProps } from 'react';

const SvgDisconnect = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.33317 12.5H4.99984V16.6667H14.9998V3.33332H4.99984V7.49999H3.33317V2.49999C3.33317 2.27898 3.42097 2.06701 3.57725 1.91073C3.73353 1.75445 3.94549 1.66666 4.1665 1.66666H15.8332C16.0542 1.66666 16.2661 1.75445 16.4224 1.91073C16.5787 2.06701 16.6665 2.27898 16.6665 2.49999V17.5C16.6665 17.721 16.5787 17.933 16.4224 18.0892C16.2661 18.2455 16.0542 18.3333 15.8332 18.3333H4.1665C3.94549 18.3333 3.73353 18.2455 3.57725 18.0892C3.42097 17.933 3.33317 17.721 3.33317 17.5V12.5ZM8.33317 9.16666V6.66666L12.4998 9.99999L8.33317 13.3333V10.8333H1.6665V9.16666H8.33317Z"
      fill="#2BD181"
    />
  </svg>
);

export default SvgDisconnect;
