import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    selectButtonsContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: center;
    `,
    selectButton: css`
      background-color: rgba(43, 209, 129, 0.2);
      color: rgba(43, 209, 129, 1);
      padding: 3px 5px;
      margin-right: 5px;
    `,
  };
};
