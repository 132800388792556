import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  const thumbSize = theme.spacing(5);

  return css`
    width: calc(${thumbSize} * 2);
    height: ${thumbSize};
    align-items: center;
    padding: 0;
    & .MuiSwitch-switchBase {
      padding: 0;
      margin: 0;
      transition-duration: 300ms;
      color: ${theme.palette.background.default};
      &.Mui-checked {
        color: ${theme.palette.background.default};
        transform: translateX(calc(${thumbSize} / 1.6));
        .MuiSwitch-thumb {
          background-color: ${theme.palette.interactive.primary};
        }
        & + .MuiSwitch-track {
          background-color: ${theme.palette.background.default};
        }
      }
      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
      &.Mui-disabled {
        .MuiSwitch-thumb {
          opacity: 0.5;
        }
      }
    }
    .MuiSwitch-thumb {
      background-color: #858585;
      box-shadow: none;
      box-sizing: border-box;
      width: ${thumbSize};
      height: ${thumbSize};
    }
    .MuiSwitch-track {
      border: 1px solid #242424;
      height: calc(${thumbSize} / 1.6);
      width: calc(${thumbSize} * 1.6);
      border-radius: ${thumbSize};
      background-color: ${theme.palette.background.default};
      opacity: 1;
    }
    .Mui-checked + .MuiSwitch-track {
      background-color: ${theme.palette.background.default};
      opacity: 1;
    }
  `;
};
