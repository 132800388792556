import { CErc20, CBttToken } from 'types/contracts';

export interface GetCTokenInterestRateModelInput {
  cTokenContract: CErc20 | CBttToken;
}

export type GetCTokenInterestRateModelOutput = {
  contractAddress: string;
};

const getCTokenInterestRateModel = async ({
  cTokenContract,
}: GetCTokenInterestRateModelInput): Promise<GetCTokenInterestRateModelOutput> => {
  const contractAddress = await cTokenContract.methods.interestRateModel().call();

  return {
    contractAddress,
  };
};

export default getCTokenInterestRateModel;
