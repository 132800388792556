import { QueryObserverOptions, useQuery } from 'react-query';
import { CTokenId } from 'types';

import getCTokenCash, { GetCTokenCashOutput } from 'clients/api/queries/getCTokenCash';
import { useCTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetCTokenCashOutput,
  Error,
  GetCTokenCashOutput,
  GetCTokenCashOutput,
  [FunctionKey.GET_V_TOKEN_CASH, CTokenId]
>;

const useGetCTokenCash = ({ cTokenId }: { cTokenId: CTokenId }, options?: Options) => {
  const cTokenContract = useCTokenContract(cTokenId);

  return useQuery(
    [FunctionKey.GET_V_TOKEN_CASH, cTokenId],
    () => getCTokenCash({ cTokenContract }),
    options,
  );
};

export default useGetCTokenCash;
