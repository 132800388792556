import * as React from 'react';
import { SVGProps } from 'react';

const SvgUsdc = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 1 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 22c5.542 0 10-4.458 10-10S17.542 2 12 2 2 6.458 2 12s4.458 10 10 10Z"
      fill="#2775CA"
    />
    <path
      d="M14.75 13.583c0-1.458-.875-1.958-2.625-2.166-1.25-.167-1.5-.5-1.5-1.084 0-.583.417-.958 1.25-.958.75 0 1.167.25 1.375.875a.313.313 0 0 0 .292.208h.666a.285.285 0 0 0 .292-.291v-.042a2.082 2.082 0 0 0-1.875-1.708v-1c0-.167-.125-.292-.333-.334h-.625c-.167 0-.292.125-.334.334v.958c-1.25.167-2.041 1-2.041 2.042 0 1.375.833 1.916 2.583 2.125 1.167.208 1.542.458 1.542 1.125 0 .666-.584 1.125-1.375 1.125-1.084 0-1.459-.459-1.584-1.084-.041-.166-.166-.25-.291-.25h-.709a.285.285 0 0 0-.291.292v.042c.166 1.041.833 1.791 2.208 2v1c0 .166.125.291.333.333h.625c.167 0 .292-.125.334-.333v-1c1.25-.209 2.083-1.084 2.083-2.209Z"
      fill="#fff"
    />
    <path
      d="M9.875 17.958c-3.25-1.166-4.917-4.791-3.708-8 .625-1.75 2-3.083 3.708-3.708.167-.083.25-.208.25-.417V5.25c0-.167-.083-.292-.25-.333-.042 0-.125 0-.167.041-3.958 1.25-6.125 5.459-4.875 9.417a7.496 7.496 0 0 0 4.875 4.875c.167.083.334 0 .375-.167.042-.041.042-.083.042-.166v-.584c0-.125-.125-.291-.25-.375Zm4.417-13c-.167-.083-.334 0-.375.167-.042.042-.042.083-.042.167v.583c0 .167.125.333.25.417 3.25 1.166 4.917 4.791 3.708 8A6.164 6.164 0 0 1 14.125 18c-.167.083-.25.208-.25.417V19c0 .167.083.292.25.333.042 0 .125 0 .167-.041 3.958-1.25 6.125-5.459 4.875-9.417a7.557 7.557 0 0 0-4.875-4.917Z"
      fill="#fff"
    />
  </svg>
);

export default SvgUsdc;
