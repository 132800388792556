import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { Asset, Market, TokenId, CTokenId } from 'types';
import {
  calculateCollateralValue,
  convertTokensToWei,
  getCErcToken,
  indexBy,
} from 'utilities';

import {
  IGetCTokenBalancesAllOutput,
  useGetAssetsInAccount,
  useGetMarkets,
  // useGetMintedVai,
  useGetCTokenBalancesAll,
} from 'clients/api';
import { TOKENS, CERC_TOKENS } from 'constants/tokens';

export interface Data {
  assets: Asset[];
  userTotalBorrowLimitCents: BigNumber;
  userTotalBorrowBalanceCents: BigNumber;
  userTotalSupplyBalanceCents: BigNumber;
  // totalXvsDistributedWei: BigNumber;
  // dailyVenusWei: BigNumber;
}

export interface UseGetUserMarketInfoOutput {
  isLoading: boolean;
  data: Data;
}

const cTokenAddresses: string[] = Object.values(CERC_TOKENS).reduce(
  (acc, item) => (item.address ? [...acc, item.address] : acc),
  [] as string[],
);

// TODO: decouple, this hook handles too many things (see https://app.clickup.com/t/2d4rfx6)
const useGetUserMarketInfo = ({
  accountAddress,
}: {
  accountAddress?: string;
}): UseGetUserMarketInfoOutput => {
  // const { data: userMintedVaiData, isLoading: isGetUserMintedVaiLoading } = useGetMintedVai(
  //   {
  //     accountAddress: accountAddress || '',
  //   },
  //   {
  //     enabled: !!accountAddress,
  //   },
  // );

  const {
    data: getMarketsData = {
      markets: [],
      dailyVenusWei: new BigNumber(0),
    },
    isLoading: isGetMarketsLoading,
  } = useGetMarkets({
    placeholderData: {
      markets: [],
      dailyVenusWei: new BigNumber(0),
    },
  });

  const marketsMap = useMemo(
    () =>
      indexBy(
        (item: Market) => item.underlyingSymbol.toLowerCase(), // index by symbol of underlying token
        getMarketsData.markets,
      ),
    [getMarketsData?.markets],
  );

  const {
    data: assetsInAccount = {
      tokenAddresses: [],
    },
    isLoading: isGetAssetsInAccountLoading,
  } = useGetAssetsInAccount(
    { accountAddress: accountAddress || '' },
    {
      enabled: !!accountAddress,
      placeholderData: {
        tokenAddresses: [],
      },
    },
  );

  const {
    data: cTokenBalancesAccount = { balances: [] },
    isLoading: isGetCTokenBalancesAccountLoading,
  } = useGetCTokenBalancesAll(
    { account: accountAddress || '', cTokenAddresses },
    { enabled: !!accountAddress, placeholderData: { balances: [] } },
  );

  const cTokenBalances = useMemo(
    () =>
      indexBy(
        (item: IGetCTokenBalancesAllOutput['balances'][number]) => item.cToken.toLowerCase(), // index by cToken address
        cTokenBalancesAccount.balances,
      ),
    [JSON.stringify(cTokenBalancesAccount)],
  );

  const isLoading =
    isGetMarketsLoading ||
    isGetAssetsInAccountLoading ||
    isGetCTokenBalancesAccountLoading;

  const data = useMemo(() => {
    const {
      assets,
      userTotalBorrowBalanceCents,
      userTotalBorrowLimitCents,
      userTotalSupplyBalanceCents,
    } = Object.values(TOKENS).reduce(
      (acc, item, index) => {
        const { assets: assetAcc } = acc;

        const toDecimalAmount = (mantissa: string) =>
          new BigNumber(mantissa).shiftedBy(-item.decimals);

        const cErcToken = getCErcToken(item.id as CTokenId);
        // if no corresponding vassets, skip
        if (!cErcToken) {
          return acc;
        }

        const market = marketsMap[item.id];
        const vtokenAddress = cErcToken.address.toLowerCase();
        const collateral = (assetsInAccount.tokenAddresses || [])
          .map((address: string) => address.toLowerCase())
          .includes(vtokenAddress);

        let walletBalance = new BigNumber(0);
        let supplyBalance = new BigNumber(0);
        let borrowBalance = new BigNumber(0);
        const percentOfLimit = '0';

        const wallet = cTokenBalances && cTokenBalances[vtokenAddress];
        if (accountAddress && wallet) {
          walletBalance = toDecimalAmount(wallet.tokenBalance);
          supplyBalance = toDecimalAmount(wallet.balanceOfUnderlying);
          borrowBalance = toDecimalAmount(wallet.borrowBalanceCurrent);
        }

        const asset: any = {
          key: index,
          id: item.id,
          img: item.asset,
          vimg: item.vasset,
          symbol: market?.underlyingSymbol || item.id.toUpperCase(),
          decimals: item.decimals,
          tokenAddress: market?.underlyingAddress,
          vsymbol: market?.symbol,
          vtokenAddress,
          supplyApy: new BigNumber(market?.supplyApy || 0),
          borrowApy: new BigNumber(market?.borrowApy || 0),
          collateralFactor: new BigNumber(market?.collateralFactor || 0).div(1e18),
          tokenPrice: new BigNumber(market?.tokenPrice || 0),
          liquidity: new BigNumber(market?.liquidity || 0),
          borrowCaps: new BigNumber(market?.borrowCaps || 0),
          treasuryTotalBorrowsCents: new BigNumber(market?.totalBorrowsUsd || 0).times(100),
          treasuryTotalSupplyCents: new BigNumber(market?.totalSupplyUsd || 0).times(100),
          treasuryTotalSupply: new BigNumber(market?.totalSupply || 0),
          treasuryTotalBorrows: new BigNumber(market?.totalBorrows2 || 0),
          walletBalance,
          supplyBalance,
          borrowBalance,
          collateral,
          percentOfLimit,
        };

        // user totals
        const borrowBalanceCents = asset.borrowBalance.times(asset.tokenPrice).times(100);
        const supplyBalanceCents = asset.supplyBalance.times(asset.tokenPrice).times(100);
        acc.userTotalBorrowBalanceCents = acc.userTotalBorrowBalanceCents.plus(borrowBalanceCents);
        acc.userTotalSupplyBalanceCents = acc.userTotalSupplyBalanceCents.plus(supplyBalanceCents);

        // acc.totalXvsDistributedWei = acc.totalXvsDistributedWei.plus(
        //   new BigNumber(market?.totalDistributed || 0).times(
        //     new BigNumber(10).pow(getToken('blend').decimals),
        //   ),
        // );

        // Create borrow limit based on assets supplied as collateral
        if (asset.collateral) {
          acc.userTotalBorrowLimitCents = acc.userTotalBorrowLimitCents.plus(
            calculateCollateralValue({
              amountWei: convertTokensToWei({ value: asset.supplyBalance, tokenId: asset.id as TokenId }),
              tokenId: asset.id as TokenId,
              tokenPriceTokens: asset.tokenPrice,
              collateralFactor: asset.collateralFactor,
            }).times(100),
          );
        }

        return { ...acc, assets: [...assetAcc, asset] };
      },
      {
        assets: [],
        userTotalBorrowBalanceCents: new BigNumber(0),
        userTotalBorrowLimitCents: new BigNumber(0),
        userTotalSupplyBalanceCents: new BigNumber(0),
        totalXvsDistributedWei: new BigNumber(0),
      } as Data,
    );

    let assetList = assets;

    // const userTotalBorrowBalanceWithUserMintedVai = userTotalBorrowBalanceCents.plus(
    //   userMintedVaiData
    //     ? convertWeiToTokens({
    //         valueWei: userMintedVaiData.mintedVaiWei,
    //         tokenId: TOKENS.vai.id as TokenId,
    //       })
    //         // Convert VAI to dollar cents (we assume 1 VAI = 1 dollar)
    //         .times(100)
    //     : 0,
    // );

    // percent of limit
    assetList = assetList.map((item: Asset) => ({
      ...item,
      percentOfLimit: new BigNumber(userTotalBorrowLimitCents).isZero()
        ? '0'
        : item.borrowBalance
            .times(item.tokenPrice)
            .div(userTotalBorrowLimitCents)
            .times(100)
            .dp(0, 1)
            .toFixed(),
    }));

    return {
      assets: assetList,
      userTotalBorrowBalanceCents,
      userTotalBorrowLimitCents,
      userTotalSupplyBalanceCents,
      // dailyVenusWei: getMarketsData.dailyVenusWei || new BigNumber(0),
    };
  }, [
    // userMintedVaiData?.mintedVaiWei.toFixed(),
    JSON.stringify(marketsMap),
    JSON.stringify(assetsInAccount),
    JSON.stringify(cTokenBalances),
    JSON.stringify(getMarketsData),
  ]);

  return {
    isLoading,
    data,
    // TODO: handle errors and retry scenarios
  };
};

export default useGetUserMarketInfo;
