import BigNumber from 'bignumber.js';

import { CErc20, CBttToken } from 'types/contracts';

export interface GetCTokenBalanceOfInput {
  cTokenContract: CErc20 | CBttToken;
  accountAddress: string;
}

export type GetCTokenBalanceOfOutput = {
  balanceWei: BigNumber;
};

const getCTokenBalanceOf = async ({
  cTokenContract,
  accountAddress,
}: GetCTokenBalanceOfInput): Promise<GetCTokenBalanceOfOutput> => {
  const res = await cTokenContract.methods.balanceOf(accountAddress).call();

  return {
    balanceWei: new BigNumber(res),
  };
};

export default getCTokenBalanceOf;
