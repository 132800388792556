import * as React from 'react';
import { SVGProps } from 'react';

const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.30678 0.684479H1.70847C1.08213 0.684479 0.57251 1.1941 0.57251 1.82044V7.32355C0.57251 7.94989 1.08213 8.45951 1.70847 8.45951H5.30678C5.93312 8.45951 6.44274 7.94989 6.44274 7.32355V1.82044C6.44274 1.1941 5.93312 0.684479 5.30678 0.684479ZM5.58184 7.32355C5.58184 7.47516 5.45839 7.59861 5.30678 7.59861H1.70847C1.55686 7.59861 1.43341 7.47516 1.43341 7.32355V1.82044C1.43341 1.66883 1.55686 1.54538 1.70847 1.54538H5.30678C5.45839 1.54538 5.58184 1.66883 5.58184 1.82044V7.32355Z"
      fill="currentColor"
    />
    <path
      d="M5.30678 9.29205H1.70847C1.08213 9.29205 0.57251 9.80167 0.57251 10.428V13.1796C0.57251 13.8059 1.08213 14.3155 1.70847 14.3155H5.30678C5.93312 14.3155 6.44274 13.8059 6.44274 13.1796V10.428C6.44274 9.80167 5.93312 9.29205 5.30678 9.29205ZM5.58184 13.1796C5.58184 13.3312 5.45839 13.4546 5.30678 13.4546H1.70847C1.55686 13.4546 1.43341 13.3312 1.43341 13.1796V10.428C1.43341 10.2764 1.55686 10.153 1.70847 10.153H5.30678C5.45839 10.153 5.58184 10.2764 5.58184 10.428V13.1796Z"
      fill="currentColor"
    />
    <path
      d="M12.2914 6.54044H8.69309C8.06675 6.54044 7.55713 7.05006 7.55713 7.6764V13.1795C7.55713 13.8059 8.06675 14.3155 8.69309 14.3155H12.2914C12.9177 14.3155 13.4274 13.8059 13.4274 13.1795V7.6764C13.4274 7.05006 12.9177 6.54044 12.2914 6.54044ZM12.5665 13.1795C12.5665 13.3311 12.443 13.4546 12.2914 13.4546H8.69309C8.54148 13.4546 8.41803 13.3311 8.41803 13.1795V7.6764C8.41803 7.52479 8.54148 7.40134 8.69309 7.40134H12.2914C12.443 7.40134 12.5665 7.52479 12.5665 7.6764V13.1795Z"
      fill="currentColor"
    />
    <path
      d="M12.2914 0.684479H8.69309C8.06675 0.684479 7.55713 1.1941 7.55713 1.82044V4.572C7.55713 5.19834 8.06675 5.70796 8.69309 5.70796H12.2914C12.9177 5.70796 13.4274 5.19834 13.4274 4.572V1.82044C13.4274 1.1941 12.9177 0.684479 12.2914 0.684479ZM12.5665 4.572C12.5665 4.72361 12.443 4.84705 12.2914 4.84705H8.69309C8.54148 4.84705 8.41803 4.72361 8.41803 4.572V1.82044C8.41803 1.66883 8.54148 1.54538 8.69309 1.54538H12.2914C12.443 1.54538 12.5665 1.66883 12.5665 1.82044V4.572Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDashboard;
