import BigNumber from 'bignumber.js';

import { InterestModel } from 'types/contracts';

export interface GetCTokenBorrowRateInput {
  interestModelContract: InterestModel;
  cashAmountWei: BigNumber;
  borrowsAmountWei: BigNumber;
  reservesAmountWei: BigNumber;
}

export type IGetCTokenBorrowRateOutput = {
  borrowRate: BigNumber;
};

const getCTokenBorrowRate = async ({
  interestModelContract,
  cashAmountWei,
  borrowsAmountWei,
  reservesAmountWei,
}: GetCTokenBorrowRateInput): Promise<IGetCTokenBorrowRateOutput> => {
  const res = await interestModelContract.methods
    .getBorrowRate(cashAmountWei.toFixed(), borrowsAmountWei.toFixed(), reservesAmountWei.toFixed())
    .call();

  return {
    borrowRate: new BigNumber(res),
  };
};

export default getCTokenBorrowRate;
