import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Variant } from './types';

export const styles = ({
  fullWidth,
  variant,
  small,
}: {
  fullWidth: boolean;
  variant: Variant;
  small: boolean;
}) => {
  const theme = useTheme();

  const getButtonVariantCss = (refVariant: Variant) => {
    if (refVariant === 'secondary') {
      return css`
        background-color: ${theme.palette.button.main};
        border-color: ${theme.palette.button.main};
        color: #000;

        :disabled {
          background-color: rgba(36, 36, 36, 1);
          border-color: rgba(36, 36, 36, 1);
          color: rgba(61, 61, 61, 1);
        }

        :hover:not(:disabled) {
          background-color: ${theme.palette.button.medium};
          border-color: ${theme.palette.button.medium};
        }

        :active:not(:disabled) {
          background-color: ${theme.palette.button.dark};
          border-color: ${theme.palette.button.dark};
        }
      `;
    }

    if (refVariant === 'tertiary') {
      return css`
        background-color: ${theme.palette.secondary.light};
        border-color: ${theme.palette.secondary.light};

        :disabled {
          background-color: ${theme.palette.secondary.light};
          border-color: ${theme.palette.secondary.light};
        }

        :hover:not(:disabled) {
          background-color: ${theme.palette.text.secondary};
          border-color: ${theme.palette.text.secondary};
        }

        :active:not(:disabled) {
          background-color: ${theme.palette.secondary.main};
          border-color: ${theme.palette.secondary.main};
        }
      `;
    }

    if (refVariant === 'text') {
      return css`
        background-color: transparent;
        color: ${theme.palette.button.main};

        :hover:not(:disabled) {
          color: ${theme.palette.button.medium};
        }

        :active:not(:disabled) {
          color: ${theme.palette.button.dark};
        }
      `;
    }

    // Primary variant
    return css`
      background-color: ${theme.palette.button.main};
      border-color: ${theme.palette.button.main};
      color: #000;

      :disabled {
        box-shadow: 0px 14px 24px rgba(111, 253, 176, 0.1);
      }

      :hover:not(:disabled) {
        background-color: ${theme.palette.button.medium};
        border-color: ${theme.palette.button.medium};
      }

      :active:not(:disabled) {
        background-color: ${theme.palette.button.dark};
        border-color: ${theme.palette.button.dark};
      }
    `;
  };

  return {
    getButton: ({ disabled }: { disabled: boolean }) => css`
      border-radius: 25px;
      padding: ${small ? theme.spacing(2, 3) : theme.spacing(3, 6, 3, 6)};
      border: 1px solid transparent;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      color: ${theme.palette.text.primary};
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      :disabled {
        color: white;
      }

      ${!disabled &&
      css`
        cursor: pointer;
      `}

      ${fullWidth &&
      css`
        width: 100%;
      `};

      ${getButtonVariantCss(variant)};
    `,
    loadingIcon: css`
      margin: 1px;
    `,
    connectButton: ({ animate }: { animate: boolean }) => css`
      border-radius: 25px;
      padding: ${theme.spacing(2, 0, 2, 3)};
      ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(2, 3)};
      }
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      color: ${theme.palette.text.primary};
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      width: 100%;
      ${getButtonVariantCss(variant)};

      :hover {
        box-shadow: 0px 14px 24px rgba(111, 253, 176, 0.1);
        background-color: ${theme.palette.button.main} !important;
        cursor: pointer;

        div {
          svg {
            animation-name: ${animate && 'arrowSlide'};
            animation-duration: 1s;
            animation-fill-mode: forwards;
          }
        }
      }

      @keyframes arrowSlide {
        0% {
          margin-left: 0px;
        }
        40% {
          margin-left: 15px;
          visibility: hidden;
        }
        70% {
          margin-left: -25px;
          visibility: hidden;
        }
        100% {
          margin-left: 5px;
        }
      }
    `,
    getIconCircle: ({ backgroundColor }: { backgroundColor?: string }) => css`
      background-color: ${backgroundColor};
      padding: 10px;
      height: 35px;
      width: 35px;
      border-radius: 20px;
      margin: -20px 2px -20px 10px;

      * {
        color: black;
      }

      ${theme.breakpoints.down('md')} {
        padding: 7px;
        height: 30px;
        width: 30px;
        border-radius: 25px;
        margin: -20px -10px -20px 10px;
      }
    `,
    label: css`
      display: inline-flex;
      color: inherit;
      margin: auto;
      font-weight: 400;
      ${theme.breakpoints.down('md')} {
        font-size: 12px;
      }
    `,
    link: css`
      font-weight: 600;
    `,
  };
};

export default styles;
