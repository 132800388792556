import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      border-radius: ${theme.shape.borderRadius.small}px;
      background-color: ${theme.palette.background.default};
      padding: ${theme.spacing(3)};
    `,
    item: css`
      display: flex;
      flex-direction: column;
      margin-right: 10px;

      &:not(:last-of-type) {
        margin-bottom: ${theme.spacing(2)};
      }
    `,
    itemLabel: css`
      font-family: Inter;
      text-transform: uppercase;
      color: ${theme.palette.text.secondary};
      margin-right: ${theme.spacing(2)};
    `,
    itemValue: css`
      font-size: 1rem;
      margin-top: 5px;
      color: ${theme.palette.text.primary};
    `,
  };
};
