/** @jsxImportSource @emotion/react */
import { css } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'translation';
import { CTokenId, TokenId } from 'types';

import { useApproveToken, useGetAllowance } from 'clients/api';
import { AuthContext } from 'context/AuthContext';

import { PrimaryButton } from '../Button';
import { Delimiter } from '../Delimiter';
import { Icon } from '../Icon';
import { LabeledInlineContent, LabeledInlineContentProps } from '../LabeledInlineContent';
import { Spinner } from '../Spinner';
import useStyles from './styles';

export interface EnableTokenUiProps {
  cTokenId: CTokenId;
  title: string | React.ReactElement;
  isTokenEnabled: boolean;
  enableToken: () => void;
  isInitialLoading?: boolean;
  isEnableTokenLoading?: boolean;
  tokenInfo?: LabeledInlineContentProps[];
  disabled?: boolean;
}

export const EnableTokenUi: React.FC<EnableTokenUiProps> = ({
  cTokenId,
  title,
  tokenInfo = [],
  children,
  enableToken,
  isTokenEnabled,
  isInitialLoading = false,
  isEnableTokenLoading = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  if (isTokenEnabled) {
    return <>{children}</>;
  }

  return (
    <div css={styles.container}>
      {isInitialLoading ? (
        <Spinner />
      ) : (
        <>
          <Icon name={cTokenId} css={styles.mainLogo} />

          <Typography component="h3" variant="body2" css={styles.mainText}>
            <Trans
              defaults={title as string}
              components={{
                Anchor: (
                  <span // eslint-disable-line jsx-a11y/anchor-has-content
                    css={css`
                      color: #2bd181;
                    `}
                  />
                ),
              }}
            />
          </Typography>

          {tokenInfo.length > 0 && (
            <div css={styles.tokenInfoContainer}>
              {/* <Delimiter css={styles.delimiter} /> */}

              {tokenInfo.map(info => (
                <LabeledInlineContent
                  {...info}
                  key={info.label}
                  css={styles.labeledInlineContent}
                />
              ))}
            </div>
          )}

          {!disabled && (
            <PrimaryButton
              disabled={disabled || isEnableTokenLoading}
              loading={isEnableTokenLoading}
              fullWidth
              onClick={enableToken}
            >
              {t('enableToken.enableButtonLabel')}
            </PrimaryButton>
          )}
        </>
      )}
    </div>
  );
};

export interface EnableTokenProps
  extends Pick<EnableTokenUiProps, 'tokenInfo' | 'disabled' | 'title' | 'cTokenId'> {
  spenderAddress: string;
}

export const EnableToken: React.FC<EnableTokenProps> = ({ cTokenId, spenderAddress, ...rest }) => {
  const { account } = useContext(AuthContext);

  const { data: getTokenAllowanceData, isLoading: isGetAllowanceLoading } = useGetAllowance(
    {
      accountAddress: account?.address || '',
      spenderAddress,
      tokenId: cTokenId as TokenId,
    },
    {
      enabled: !!account?.address,
    },
  );

  const isTokenApproved =
    // @ts-ignore (coz MARKET is not deployed)
    cTokenId === 'btt' ||
    (!!getTokenAllowanceData && getTokenAllowanceData.allowanceWei.isGreaterThan(0));

  const { mutate: contractApproveToken, isLoading: isApproveTokenLoading } = useApproveToken({
    tokenId: cTokenId as TokenId,
  });

  const approveToken = () => {
    if (account?.address) {
      contractApproveToken({
        accountAddress: account.address,
        spenderAddress,
      });
    }
  };

  return (
    <EnableTokenUi
      {...rest}
      cTokenId={cTokenId}
      enableToken={approveToken}
      isTokenEnabled={isTokenApproved}
      isEnableTokenLoading={isApproveTokenLoading}
      isInitialLoading={isGetAllowanceLoading}
      disabled={!account}
    />
  );
};

export default EnableToken;
