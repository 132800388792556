import * as React from 'react';
import { SVGProps } from 'react';

const SvgMarket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0.5C10.8954 0.5 10 1.39543 10 2.5V12.5C10 13.6046 10.8954 14.5 12 14.5C13.1046 14.5 14 13.6046 14 12.5V2.5C14 1.39543 13.1046 0.5 12 0.5ZM13 12.5C13 13.0523 12.5523 13.5 12 13.5C11.4477 13.5 11 13.0523 11 12.5V2.5C11 1.94772 11.4477 1.5 12 1.5C12.5523 1.5 13 1.94772 13 2.5V12.5ZM5 12.5C5 13.6046 5.89543 14.5 7 14.5C8.10457 14.5 9 13.6046 9 12.5V5.5C9 4.39543 8.10457 3.5 7 3.5C5.89543 3.5 5 4.39543 5 5.5V12.5ZM6 5.5C6 4.94772 6.44772 4.5 7 4.5C7.55228 4.5 8 4.94772 8 5.5V12.5C8 13.0523 7.55228 13.5 7 13.5C6.44772 13.5 6 13.0523 6 12.5V5.5ZM0 12.5C0 13.6046 0.895431 14.5 2 14.5C3.10457 14.5 4 13.6046 4 12.5V8.5C4 7.39543 3.10457 6.5 2 6.5C0.895431 6.5 0 7.39543 0 8.5V12.5ZM1 8.5C1 7.94772 1.44772 7.5 2 7.5C2.55228 7.5 3 7.94772 3 8.5V12.5C3 13.0523 2.55228 13.5 2 13.5C1.44772 13.5 1 13.0523 1 12.5V8.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMarket;
