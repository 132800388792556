/** @jsxImportSource @emotion/react */
import { Paper } from '@mui/material';
import BigNumber from 'bignumber.js';
import { Delimiter } from 'components';
import React, { useContext } from 'react';
import { Asset, TokenId } from 'types';

import { TOKENS } from 'constants/tokens';
import BorrowRepayModal from 'pages/Dashboard/Modals/BorrowRepay';

import { useStyles } from '../styles';
import BorrowMarketTable, { BorrowMarketTableProps } from './BorrowMarketTable';
import BorrowingTable, { BorrowingUiProps } from './BorrowingTable';

export interface BorrowMarketUiProps {
  className?: string;
  borrowMarketAssets: Asset[];
  borrowingAssets: Asset[];
  userTotalBorrowLimitCents: BigNumber;
}

export const BorrowMarketUi: React.FC<BorrowMarketUiProps> = ({
  className,
  borrowingAssets,
  borrowMarketAssets,
  userTotalBorrowLimitCents,
}) => {
  const [selectedAssetId, setSelectedAssetId] = React.useState<Asset['id'] | undefined>(undefined);
  const styles = useStyles();

  const rowOnClick: BorrowMarketTableProps['rowOnClick'] | BorrowingUiProps['rowOnClick'] = (
    _e,
    row,
  ) => {
    // const assetId = row[0].value as TokenId;

    // // Block action and show warning modal if user has LUNA or UST enabled as
    // // collateral and is attempting to open the borrow modal of other assets
    // if (hasLunaOrUstCollateralEnabled && assetId !== TOKENS.luna.id && assetId !== TOKENS.ust.id) {
    //   openLunaUstWarningModal();
    //   return;
    // }

    setSelectedAssetId(row[0].value as TokenId);
  };

  const selectedAsset = React.useMemo(
    () =>
      [...borrowingAssets, ...borrowMarketAssets].find(
        marketAsset => marketAsset.id === selectedAssetId,
      ),
    [selectedAssetId, JSON.stringify(borrowingAssets), JSON.stringify(borrowMarketAssets)],
  );

  return (
    <>
      <Paper className={className} css={styles.tableContainer}>
        {borrowingAssets.length > 0 && (
          <>
            <BorrowingTable
              assets={borrowingAssets}
              userTotalBorrowLimitCents={userTotalBorrowLimitCents}
              rowOnClick={rowOnClick}
            />
            {/* <Delimiter css={styles.delimiter} /> */}
          </>
        )}
        <BorrowMarketTable
          assets={borrowMarketAssets}
          rowOnClick={rowOnClick}
        />
      </Paper>

      {selectedAsset && (
        <BorrowRepayModal
          asset={selectedAsset}
          onClose={() => setSelectedAssetId(undefined)}
        />
      )}
    </>
  );
};

const BorrowMarket: React.FC<
  Omit<BorrowMarketUiProps, 'hasLunaOrUstCollateralEnabled' | 'openLunaUstWarningModal'>
> = ({
  className,
  borrowMarketAssets,
  borrowingAssets,
  userTotalBorrowLimitCents,
}) => (
    <BorrowMarketUi
      className={className}
      borrowingAssets={borrowingAssets}
      borrowMarketAssets={borrowMarketAssets}
      userTotalBorrowLimitCents={userTotalBorrowLimitCents}
    />
  );

export default BorrowMarket;
