/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import { LabeledInlineContent } from 'components';
import React from 'react';
import { useTranslation } from 'translation';

import Card from '../Card';
import { Stat } from '../types';
import { useStyles } from './styles';

export interface MarketInfoProps {
  stats: { category: string; values: Stat[] }[];
  testId?: string;
}

const MarketInfo: React.FC<MarketInfoProps> = ({ stats, testId }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <Card title={t('marketDetails.marketInfo.title')} testId={testId}>
      <ul css={styles.itemList}>
        {stats.map((stat, i) => (
          <div key={`market-info-catregory-${stat.values.toString()}`}>
            <Typography css={styles.categoryLabel} variant="body1">
              {stat.category}
            </Typography>
            {stat.values.map(value => (
              <li css={styles.item} key={`market-info-stat-${value.label}`}>
                <LabeledInlineContent label={value.label}>
                  <span css={styles.value}>{value.value}</span>
                </LabeledInlineContent>
              </li>
            ))}
          </div>
        ))}
      </ul>
    </Card>
  );
};

export default MarketInfo;
