import * as React from 'react';
import { SVGProps } from 'react';

const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="14" height="14" rx="7" fill="#6FFDB0" fillOpacity="0.2" />
    <path d="M10 8.5L7 5.5L4 8.5" stroke="#2BD181" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default SvgChevronUp;
