import * as React from 'react';
import { SVGProps } from 'react';

const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.08862 5.37772H9.02196"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.37769 1.60016L10.1555 5.37794L6.37769 9.15571"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgArrowRight;
