/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'translation';
import { Asset, CTokenId } from 'types';
import { formatToReadablePercentage, getCErcToken } from 'utilities';

import EnableToken from 'components/EnableToken';
import { LabeledInlineContentProps } from 'components/LabeledInlineContent';
import { AuthContext } from 'context/AuthContext';

import { SecondaryButton } from '../Button';
import { useStyles } from './styles';

export interface PromptProps {
  message: string;
  openAuthModal: () => void;
  className?: string;
  connected: boolean;
  asset: Asset;
  tokenInfo: LabeledInlineContentProps[] | undefined;
}

export const Prompt: React.FC<PromptProps> = ({
  message,
  openAuthModal,
  className,
  children,
  connected,
  asset,
  tokenInfo,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const cErcTokenContractAddress = getCErcToken(asset.id as CTokenId).address;

  // Render prompt if user aren't connected with any wallet
  if (connected) {
    return <>{children}</>;
  }
  return (
    <div css={styles.container} className={className}>
      {asset && (
        <EnableToken
          cTokenId={asset.id as CTokenId}
          spenderAddress={cErcTokenContractAddress}
          title={message}
          tokenInfo={tokenInfo}
        />
      )}

      <SecondaryButton fullWidth onClick={openAuthModal}>
        {t('connectWallet.connectButton')}
      </SecondaryButton>
    </div>
  );
};

export const ConnectWallet: React.FC<Omit<PromptProps, 'connected' | 'openAuthModal'>> = props => {
  const { account, openAuthModal } = React.useContext(AuthContext);
  return <Prompt {...props} openAuthModal={openAuthModal} connected={!!account} />;
};
