import sample from 'lodash/sample';
import { BttcChainId } from 'types';

import { BSC_SCAN_URLS } from 'constants/btt';
import { API_ENDPOINT_URLS, RPC_URLS } from 'constants/endpoints';

export interface Config {
  chainId: BttcChainId;
  isOnTestnet: boolean;
  rpcUrl: string;
  apiUrl: string;
  bscScanUrl: string;
  bitlendBackend: boolean;
}

const chainId: BttcChainId = process.env.REACT_APP_CHAIN_ID
  ? parseInt(process.env.REACT_APP_CHAIN_ID, 10)
  : BttcChainId.MAINNET;

const isOnTestnet = chainId === BttcChainId.TESTNET;
const rpcUrl = sample(RPC_URLS[chainId]) as string;
const apiUrl = API_ENDPOINT_URLS[chainId];
const bscScanUrl = BSC_SCAN_URLS[chainId];
const bitlendBackend = process.env.REACT_APP_BITLEND_BACKEND === 'true';

const config: Config = {
  chainId,
  isOnTestnet,
  rpcUrl,
  apiUrl,
  bscScanUrl,
  bitlendBackend,
};

export default config;
