import { MutationObserverOptions, useMutation } from 'react-query';
import { CTokenId } from 'types';

import {
  RepayBnbOutput,
  RepayNonBnbCTokenInput,
  queryClient,
  repayNonBnbCToken,
} from 'clients/api';
import { useCTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
  RepayBnbOutput,
  Error,
  Omit<RepayNonBnbCTokenInput, 'cTokenContract'>
>;

const useRepayNonBnbCToken = (
  { cTokenId }: { cTokenId: Exclude<CTokenId, 'btt'> },
  options?: Options,
) => {
  const cTokenContract = useCTokenContract(cTokenId);

  return useMutation(
    FunctionKey.REPAY_NON_BNB_V_TOKEN,
    params =>
      repayNonBnbCToken({
        cTokenContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        const { fromAccountAddress } = onSuccessParams[1];

        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);
        queryClient.invalidateQueries(FunctionKey.GET_MARKETS);
        queryClient.invalidateQueries([
          FunctionKey.GET_V_TOKEN_BORROW_BALANCE,
          { accountAddress: fromAccountAddress, cTokenId },
        ]);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useRepayNonBnbCToken;
