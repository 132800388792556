import * as React from 'react';
import { SVGProps } from 'react';

const SvgBitlend = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="53" cy="53" r="53" fill="black" />
    <path
      d="M76.5741 50.1195V43.5482C76.5737 42.1089 76.2638 40.6866 75.6655 39.3778C75.0672 38.069 74.1945 36.9044 73.1066 35.9632L69.0076 32.3853C67.1761 30.8352 64.8524 29.9895 62.4539 30.0001H27.4336C25.9925 30.0001 24.6104 30.5731 23.5915 31.5929C22.5725 32.6128 22 33.9961 22 35.4384V47.8416C22.0031 48.2001 22.1476 48.5428 22.4021 48.7952C22.6565 49.0476 23.0002 49.1892 23.3584 49.1892H28.2915C28.6497 49.1924 28.9922 49.337 29.2444 49.5916C29.4965 49.8463 29.638 50.1903 29.638 50.5488V55.4743C29.6349 55.8308 29.492 56.1717 29.2402 56.4238C28.9883 56.6759 28.6477 56.8188 28.2915 56.8219H23.3584C23.0002 56.8219 22.6565 56.9635 22.4021 57.2159C22.1476 57.4683 22.0031 57.8111 22 58.1696V75.9992H71.1882C74.6505 76.0377 77.9986 74.7607 80.5572 72.4256C83.1158 70.0906 84.6944 66.8713 84.9747 63.4171C85.1478 60.5983 84.4294 57.7966 82.9214 55.4096C81.4135 53.0226 79.1926 51.1716 76.5741 50.1195ZM29.6737 37.6686H62.4659C63.0268 37.6679 63.5689 37.8713 63.9911 38.2411L68.0901 41.8189C68.345 42.04 68.5501 42.3127 68.6918 42.619C68.8335 42.9254 68.9087 43.2583 68.9123 43.5959V49.2011H31.0202C30.662 49.198 30.3196 49.0534 30.0674 48.7988C29.8152 48.5441 29.6737 48.2001 29.6737 47.8416V37.6686ZM71.3788 68.3068H29.6737V58.1815C29.6737 57.823 29.8152 57.479 30.0674 57.2244C30.3196 56.9697 30.662 56.8251 31.0202 56.8219H71.5337C72.3356 56.8229 73.1284 56.992 73.861 57.3182C74.5936 57.6444 75.2499 58.1206 75.7876 58.716C76.3252 59.3114 76.7323 60.0129 76.9827 60.7753C77.2331 61.5378 77.3212 62.3442 77.2414 63.1428C77.0483 64.5719 76.3444 65.8825 75.2599 66.832C74.1754 67.7814 72.7839 68.3054 71.3431 68.3068H71.3788Z"
      fill="white"
    />
  </svg>
);

export default SvgBitlend;
