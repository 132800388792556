import { CTokenId, TokenId } from 'types';
import { getCErcToken, getToken } from 'utilities';
import { CERC_TOKENS } from 'constants/tokens';
import { isRunningInBinanceChainWallet } from './walletDetectionUtils';


const addTokenToWallet = async (tokenId: TokenId) => {
  const token = getToken(tokenId);
  const isInBCW = isRunningInBinanceChainWallet();
  const provider = (isInBCW ? (window.BinanceChain as Record<string, string>) : window.ethereum);
  const providerParams = [];

  // if (Object.keys(CERC_TOKENS).includes(tokenId)) {
  //   const cBttToken = getCErcToken(tokenId as CTokenId);
  //   providerParams.push({
  //     type: 'ERC20',
  //     options: {
  //       address: cBttToken.address,
  //       symbol: cBttToken.symbol,
  //       decimals: cBttToken.decimals,
  //       image: `${window.location.origin}${token.asset}`,
  //     },
  //   });
  // }

  providerParams.push({
    type: 'ERC20',
    options: {
      address: token.address,
      symbol: token.symbol,
      decimals: token.decimals,
      image: `${window.location.origin}${token.asset}`,
    },
  });

  const added = (
      await Promise.all(
          providerParams.map((params) =>
              provider?.request({
                method: 'wallet_watchAsset',
                params,
              }),
          ),
      )
  ).every(Boolean);

  console.log('added', added);

  return added;
};

export default addTokenToWallet;
