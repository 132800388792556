import { QueryObserverOptions, useQuery } from 'react-query';
import { CTokenId } from 'types';

import getCTokenBalanceOf, {
  GetCTokenBalanceOfInput,
  GetCTokenBalanceOfOutput,
} from 'clients/api/queries/getCTokenBalanceOf';
import { useCTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

interface TrimmedParams extends Omit<GetCTokenBalanceOfInput, 'cTokenContract'> {
  cTokenId: CTokenId;
}

type Options = QueryObserverOptions<
  GetCTokenBalanceOfOutput,
  Error,
  GetCTokenBalanceOfOutput,
  GetCTokenBalanceOfOutput,
  [FunctionKey.GET_V_TOKEN_BALANCE, TrimmedParams]
>;

const useGetCTokenBalanceOf = ({ accountAddress, cTokenId }: TrimmedParams, options?: Options) => {
  const cTokenContract = useCTokenContract(cTokenId as CTokenId);

  return useQuery(
    [FunctionKey.GET_V_TOKEN_BALANCE, { accountAddress, cTokenId }],
    () => getCTokenBalanceOf({ cTokenContract, accountAddress }),
    options,
  );
};

export default useGetCTokenBalanceOf;
