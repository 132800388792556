import { VenusLens, Comptroller } from 'types/contracts';
import { coinGeckoRestService } from 'utilities';
import Web3 from 'web3';
import config from 'config';
import { ethers } from 'ethers';
import Fraction from 'fraction.js';
import { TOKENS, CERC_TOKENS, CTOKEN_DECIMALS } from '../../../../constants/tokens';
import { TokenId, CTokenId, CTokenMetadata } from '../../../../types';
import { getVenusLensContract, getComptrollerContract } from '../../../contracts/getters';
import { RPC_URLS } from '../../../../constants/endpoints';


const coingeckoPriceMapping = {
  [TOKENS.btc.id]: 'bitcoin',
  [TOKENS.btt.id]: 'bittorrent',
  [TOKENS.eth.id]: 'ethereum',
  [TOKENS.trx.id]: 'tron',
  [TOKENS.usdc.id]: 'usd-coin',
};

interface MarketResponse {
  address: string;
  symbol: string;
  name: string;
  underlyingAddress: string;
  underlyingName: string;
  underlyingSymbol: string;
  underlyingDecimal: number;
  venusSpeeds: string;
  venusBorrowIndex: string;
  venusSupplyIndex: string;
  borrowRatePerBlock: string;
  supplyRatePerBlock: string;
  exchangeRate: string;
  underlyingPrice: string;
  totalBorrows: string;
  totalBorrows2: string;
  totalBorrowsUsd: string;
  totalSupply: string;
  totalSupply2: string;
  totalSupplyUsd: string;
  cash: string;
  totalReserves: string;
  reserveFactor: string;
  collateralFactor: string;
  borrowApy: number;
  supplyApy: string;
  liquidity: string;
  tokenPrice: string;
  borrowCaps: string;
  lastCalculatedBlockNumber: number;
  borrowerCount: number;
  supplierCount: number;
}

const toCTokenMetadata = (
  cToken: string,
  exchangeRateCurrent: string,
  supplyRatePerBlock: string,
  borrowRatePerBlock: string,
  reserveFactorMantissa: string,
  totalBorrows: string,
  totalReserves: string,
  totalSupply: string,
  totalCash: string,
  isListed: boolean,
  collateralFactorMantissa: string,
  underlyingAssetAddress: string,
  cTokenDecimals: string,
  underlyingDecimals: string,
  compSupplySpeed: string,
  compBorrowSpeed: string,
  borrowCap: string,
): CTokenMetadata => {
  const metadata: CTokenMetadata = {
    cToken,
    exchangeRateCurrent,
    supplyRatePerBlock,
    borrowRatePerBlock,
    reserveFactorMantissa,
    totalBorrows,
    totalReserves,
    totalSupply,
    totalCash,
    isListed,
    collateralFactorMantissa,
    underlyingAssetAddress,
    cTokenDecimals,
    underlyingDecimals,
    compSupplySpeed,
    compBorrowSpeed,
    borrowCap,
  };
  return metadata;
};

export async function queryMarkets<D>(): Promise<
  | {
    status: number;
    data: { data: D; status: boolean } | undefined;
  }
  | {
    data: undefined;
    result: 'error';
    message: string;
    status: boolean;
  }
> {
  const web3 = new Web3(RPC_URLS[config.chainId][0]);
  const venusLensContract: VenusLens = getVenusLensContract(web3);
  const comptrollerContract: Comptroller = getComptrollerContract(web3);

  const cTokenKeys = Object.keys(CERC_TOKENS);
  const cTokenAddresses: string[] = [];
  const coingeckoIds: string[] = [];

  for (let idx = 0; idx < cTokenKeys.length; idx++) {
    const key = cTokenKeys[idx] as CTokenId;
    cTokenAddresses.push(CERC_TOKENS[key].address);
    coingeckoIds.push(coingeckoPriceMapping[key]);
  }
  try {
    // https://www.coingecko.com/en/api/documentation
    const coingeckoResponse = await coinGeckoRestService({
      endpoint: '/simple/price',
      method: 'GET',
      params: {
        ids: coingeckoIds.join('%2C'),
        vs_currencies: 'usd',
      },
    });

    const allCTokenMetadata: Array<CTokenMetadata> = [];
    const responseMetadata = await venusLensContract.methods.cTokenMetadataAll(cTokenAddresses).call();
    for (let idx = 0; idx < responseMetadata.length; idx++) {
      allCTokenMetadata.push(toCTokenMetadata(...responseMetadata[idx]));
    }

    const processedMarkets: any[] = [];
    const prices = coingeckoResponse?.data as any;


    for (let idx = 0; idx < allCTokenMetadata.length; idx++) {
      const id = cTokenKeys[idx] as CTokenId;

      const metadata = allCTokenMetadata[idx];
      const blocksPerDay = (60 * 60 * 24) / 2;

      const borrowApy = ((((Number(metadata.borrowRatePerBlock) /
        (10 ** 18) * blocksPerDay) + 1) ** 365) - 1) * 100;

      const supplyApy = ((((Number(metadata.supplyRatePerBlock) /
        (10 ** 18) * blocksPerDay) + 1) ** 365) - 1) * 100;

      const underlyingTokenPrice = Number(prices[coingeckoPriceMapping[cTokenKeys[idx]]].usd).toFixed(TOKENS[id].decimals);
      const underlyingTokenPriceFraction = new Fraction(prices[coingeckoPriceMapping[cTokenKeys[idx]]].usd);

      const promisses = [
        comptrollerContract.methods.compSupplyState(CERC_TOKENS[id].address).call(),
        comptrollerContract.methods.compBorrowState(CERC_TOKENS[id].address).call(),
        comptrollerContract.methods.compSpeeds(CERC_TOKENS[id].address).call(),
      ];

      let compSupplyStateIndex;
      let compBorrowStateIndex;
      let compSpeeds;
      Promise.all(promisses).then(res => {
        // eslint-disable-next-line prefer-destructuring
        compSupplyStateIndex = res[0][0];
        // eslint-disable-next-line prefer-destructuring
        compBorrowStateIndex = res[1][0];
        // eslint-disable-next-line prefer-destructuring
        compSpeeds = res[2].toString();
      });

      // CTokenUnderlyingPrice
      const market: MarketResponse = {
        address: cTokenAddresses[idx],
        borrowApy,
        borrowCaps: metadata.borrowCap,
        borrowRatePerBlock: metadata.borrowRatePerBlock,
        borrowerCount: 0,
        cash: metadata.totalCash,
        collateralFactor: metadata.collateralFactorMantissa,
        exchangeRate: metadata.exchangeRateCurrent,
        lastCalculatedBlockNumber: 0,
        liquidity: ethers.utils.formatUnits(ethers.BigNumber.from(metadata.totalCash).mul(underlyingTokenPriceFraction.n).div(underlyingTokenPriceFraction.d), TOKENS[id].decimals).toString(),
        name: TOKENS[id].id,
        reserveFactor: metadata.reserveFactorMantissa,
        supplierCount: 0,
        supplyApy: supplyApy.toString(),
        supplyRatePerBlock: metadata.supplyRatePerBlock,
        symbol: CERC_TOKENS[id].symbol,
        tokenPrice: String(underlyingTokenPrice),
        totalBorrows: metadata.totalBorrows,
        totalBorrows2: ethers.utils.formatUnits(metadata.totalBorrows, TOKENS[id].decimals).toString(),
        totalBorrowsUsd: ethers.utils.formatUnits(ethers.BigNumber.from(metadata.totalBorrows).mul(underlyingTokenPriceFraction.n).div(underlyingTokenPriceFraction.d), TOKENS[id].decimals).toString(),
        totalReserves: metadata.totalReserves,
        totalSupply: metadata.totalSupply,
        totalSupply2: ethers.utils.formatUnits(ethers.BigNumber.from(metadata.totalSupply), CTOKEN_DECIMALS).toString(),
        totalSupplyUsd: ethers.utils.formatUnits(ethers.BigNumber.from(metadata.totalSupply).mul(metadata.exchangeRateCurrent).mul(underlyingTokenPriceFraction.n).div(underlyingTokenPriceFraction.d), 18 + TOKENS[id].decimals).toString(),
        underlyingAddress: TOKENS[id].address || '',
        underlyingDecimal: TOKENS[id].decimals,
        underlyingName: TOKENS[id].id,
        underlyingPrice: ethers.utils.parseUnits(underlyingTokenPrice, 18).toString(),
        underlyingSymbol: TOKENS[id].symbol,
        venusBorrowIndex: compBorrowStateIndex || '',
        venusSpeeds: compSpeeds || '',
        venusSupplyIndex: compSupplyStateIndex || '',
      };
      processedMarkets.push(market);
    }
    return {
      status: 200,
      data: {
        status: true,
        data: {
          dailyVenus: 0,
          markets: processedMarkets,
          request: {},
          venusRate: '',
        } as any,
      },
    };
  } catch (e) {
    console.log('Error to data:', e);
    return {
      data: undefined,
      result: 'error',
      message: '',
      status: false,
    };
  }
}
