import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      margin-top: ${theme.spacing(10)};
      ${theme.breakpoints.down('md')} {
        margin-top: ${theme.spacing(8)};
      }
    `,
    input: css`
      margin-bottom: ${theme.spacing(1)};
    `,
    labelDiv: css`
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    `,
    greyLabel: css`
      text-align: right;
      font-family: Inter;
      text-transform: uppercase;
      font-size: 10px;
      color: ${theme.palette.text.secondary};
    `,
    redLabel: css`
      text-align: right;
      font-family: Inter;
      text-transform: uppercase;
      font-size: 10px;
      color: red;
    `,
    whiteLabel: css`
      color: ${theme.palette.text.primary};
    `,
    totalAndLimit: css`
      display: flex;
      justify-content: space-between;
    `,
    notice: css`
      font-family: Inter;
      font-size: 10px;
      margin-top: ${theme.spacing(3)};
      padding: ${theme.spacing(4)};
    `,
    icon: css`
      color: ${theme.palette.interactive.success};
      margin: 0 ${theme.spacing(2.5)};
      width: ${theme.shape.iconSize.medium}px;
      height: ${theme.shape.iconSize.medium}px;
    `,
    getRow: ({ isLast }: { isLast: boolean }) => css`
      margin-bottom: ${theme.spacing(isLast ? 8 : 3)};

      ${theme.breakpoints.down('md')} {
        margin-bottom: ${theme.spacing(isLast ? 6 : 3)};

        span {
          font-size: ${theme.typography.small1.fontSize};
        }
      }
    `,
    label: css`
      font-family: Inter;
      font-size: 10px;
    `,
  };
};
