import BigNumber from 'bignumber.js';
import { checkForTokenTransactionError } from 'errors';
import { CTokenId } from 'types';
import type { TransactionReceipt } from 'web3-core/types';

import { CTokenContract } from 'clients/contracts/types';
import MAX_UINT256 from 'constants/maxUint256';

export interface RepayNonBnbCTokenInput {
  cTokenContract: CTokenContract<Exclude<CTokenId, 'btt'>>;
  fromAccountAddress: string;
  amountWei: BigNumber;
  isRepayingFullLoan?: boolean;
}

export type RepayNonBnbCTokenOutput = TransactionReceipt;

const repayNonBnbCToken = async ({
  cTokenContract,
  fromAccountAddress,
  amountWei,
  isRepayingFullLoan = false,
}: RepayNonBnbCTokenInput): Promise<RepayNonBnbCTokenOutput> => {
  const resp = await cTokenContract.methods
    .repayBorrow(isRepayingFullLoan ? MAX_UINT256.toFixed() : amountWei.toFixed())
    .send({ from: fromAccountAddress });
  return checkForTokenTransactionError(resp);
};

export default repayNonBnbCToken;
