import config from 'config';

import btc from 'assets/img/tokens/btc.png';
import btt from 'assets/img/tokens/btt.png';
import eth from 'assets/img/tokens/eth.png';
import trx from 'assets/img/tokens/trx.png';
import usdc from 'assets/img/tokens/usdc.png';
import vbtt from 'assets/img/tokens/vbch.png';
import vbeth from 'assets/img/tokens/vbeth.png';
import vbtc from 'assets/img/tokens/vbtc.png';
import vtrx from 'assets/img/tokens/vtrx.png';
import vusdc from 'assets/img/tokens/vusdc.png';
import vblend from 'assets/img/tokens/vxvs.png';
import blend from 'assets/img/tokens/xvs.svg';

import CERC_TOKEN_ADDRESSES from './contracts/addresses/cErcTokens.json';
import TOKEN_ADDRESSES from './contracts/addresses/tokens.json';

export const CTOKEN_DECIMALS = 8;

export const TOKENS = config.isOnTestnet
  ? {
      btt: {
        id: 'btt',
        symbol: 'BTT',
        name: 'BitTorrent',
        decimals: 18,
        address: TOKEN_ADDRESSES.btt[1029],
        asset: btt,
        vasset: vbtt,
      },
      usdc: {
        id: 'usdc',
        symbol: 'USDC',
        name: 'USD Coin',
        decimals: 6,
        address: TOKEN_ADDRESSES.usdc[1029],
        asset: usdc,
        vasset: vusdc,
      },
      blend: {
        id: 'blend',
        symbol: 'BLND',
        name: 'Blend',
        decimals: 18,
        address: TOKEN_ADDRESSES.blend[1029],
        asset: blend,
        vasset: vblend,
      },
      btc: {
        id: 'btc',
        symbol: 'BTC',
        name: 'Bitcoin',
        decimals: 8,
        address: TOKEN_ADDRESSES.btc[1029],
        asset: btc,
        vasset: vbtc,
      },
      eth: {
        id: 'eth',
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18,
        address: TOKEN_ADDRESSES.eth[1029],
        asset: eth,
        vasset: vbeth,
      },
      trx: {
        id: 'trx',
        symbol: 'TRX',
        name: 'Tron',
        decimals: 6,
        address: TOKEN_ADDRESSES.trx[1029],
        asset: trx,
        vasset: vtrx,
      },
    }
  : {
      btt: {
        id: 'btt',
        symbol: 'BTT',
        name: 'BitTorrent',
        decimals: 18,
        address: TOKEN_ADDRESSES.btt[199],
        asset: btt,
        vasset: vbtt,
      },
      usdc: {
        id: 'usdc',
        symbol: 'USDC',
        name: 'USD Coin',
        decimals: 6,
        address: TOKEN_ADDRESSES.usdc[199],
        asset: usdc,
        vasset: vusdc,
      },
      blend: {
        id: 'blend',
        symbol: 'BLND',
        name: 'Blend',
        decimals: 18,
        address: TOKEN_ADDRESSES.blend[199],
        asset: blend,
        vasset: vblend,
      },
      btc: {
        id: 'btc',
        symbol: 'BTC',
        name: 'Bitcoin',
        decimals: 8,
        address: TOKEN_ADDRESSES.btc[199],
        asset: btc,
        vasset: vbtc,
      },
      eth: {
        id: 'eth',
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18,
        address: TOKEN_ADDRESSES.eth[199],
        asset: eth,
        vasset: vbeth,
      },
      trx: {
        id: 'trx',
        symbol: 'TRX',
        name: 'Tron',
        decimals: 6,
        address: TOKEN_ADDRESSES.trx[199],
        asset: trx,
        vasset: vtrx,
      },
    };

export const CERC_TOKEN_DECIMALS = 8;

export const CERC_TOKENS = config.isOnTestnet
  ? {
      // btt: {
      //   id: 'btt',
      //   symbol: 'bBTT',
      //   name: 'Bitlend BitTorrent',
      //   address: CERC_TOKEN_ADDRESSES.btt[1029],
      //   decimals: CERC_TOKEN_DECIMALS,
      // },
      eth: {
        id: 'eth',
        symbol: 'bWETH',
        name: 'Bitlend Ethereum',
        address: CERC_TOKEN_ADDRESSES.eth[1029],
        decimals: CERC_TOKEN_DECIMALS,
      },
      // btc: {
      //   id: 'btc',
      //   symbol: 'bBTC',
      //   name: 'Bitlend Bitcoin',
      //   address: CERC_TOKEN_ADDRESSES.btc[1029],
      //   decimals: CERC_TOKEN_DECIMALS,
      // },
      trx: {
        id: 'trx',
        symbol: 'bTRX',
        name: 'Bitlend Tron',
        address: CERC_TOKEN_ADDRESSES.trx[1029],
        decimals: CERC_TOKEN_DECIMALS,
      },
      usdc: {
        id: 'usdc',
        symbol: 'bUSDC',
        name: 'Bitlend USD Coin',
        address: CERC_TOKEN_ADDRESSES.usdc[1029],
        decimals: CERC_TOKEN_DECIMALS,
      },
      // blend: {
      //   id: 'blend',
      //   symbol: 'cBLEND',
      //   address: CERC_TOKEN_ADDRESSES.blend[1029],
      //   decimals: CERC_TOKEN_DECIMALS,
      // },
    }
  : {
      // btt: {
      //   id: 'btt',
      //   symbol: 'bBTT',
      //   name: 'Bitlend BitTorrent',
      //   address: CERC_TOKEN_ADDRESSES.btt[199],
      //   decimals: CERC_TOKEN_DECIMALS,
      // },
      eth: {
        id: 'eth',
        symbol: 'bWETH',
        name: 'Bitlend Ethereum',
        address: CERC_TOKEN_ADDRESSES.eth[199],
        decimals: CERC_TOKEN_DECIMALS,
      },
      // btc: {
      //   id: 'btc',
      //   symbol: 'bBTC',
      //   name: 'Bitlend Bitcoin',
      //   address: CERC_TOKEN_ADDRESSES.btc[199],
      //   decimals: CERC_TOKEN_DECIMALS,
      // },
      trx: {
        id: 'trx',
        symbol: 'bTRX',
        name: 'Bitlend Tron',
        address: CERC_TOKEN_ADDRESSES.trx[199],
        decimals: CERC_TOKEN_DECIMALS,
      },
      usdc: {
        id: 'usdc',
        symbol: 'bUSDC',
        name: 'Bitlend USD Coin',
        address: CERC_TOKEN_ADDRESSES.usdc[199],
        decimals: CERC_TOKEN_DECIMALS,
      },
      // blend: {
      //   id: 'blend',
      //   symbol: 'cBLEND',
      //   address: CERC_TOKEN_ADDRESSES.blend[199],
      //   decimals: CERC_TOKEN_DECIMALS,
      // },
    };
