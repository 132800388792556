/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'translation';
import { IRiskManagementTokenData, RiskManagementChains } from 'clients/api/queries/getRiskManagementData/types';
import { Typography } from '@mui/material';
import BackButton from 'components/Layout/Header/Title/BackButton';
import { useStyles } from '../styles';
import {
    useGetRiskManagementChainData,
} from '../../../clients/api/queries/getRiskManagementData/useGetRiskManagementData';

type RiskManagementDetailsProps = {
    chainId: RiskManagementChains;
    tokenId: string;
};

type RiskManagementDetailsUiProps = {
    data: IRiskManagementTokenData;
};

const RiskManagementDetailsUi: React.FC<RiskManagementDetailsUiProps> = ({ data }) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            <BackButton>
                <Typography component="h1" variant="h3">{data.token.symbol} on {data.token.chain}</Typography>
            </BackButton>
            <Typography component="p" variant="body2"><strong>Coingecko: </strong><a href={data.coingecko} target="_blank" rel="noreferrer">{data.coingecko}</a></Typography>
            <Typography component="p" variant="body2"><strong>Coinmarketcap: </strong><a href={data.coinmarketcap} target="_blank" rel="noreferrer">{data.coinmarketcap}</a></Typography>
            <Typography component="p" variant="body2" style={{ marginBottom: 16 }}><strong>Contract: </strong><a href={data.contract} target="_blank" rel="noreferrer">{data.contract}</a></Typography>
            <div style={{ maxWidth: 1080 }}>
                <table css={styles.rubrikTable}>
                    <tbody>
                    <tr className="rubrik__table-header">
                        <th className="text-center bold" colSpan={2}>{t('riskManagement.details.headers.smartContractRisk')}</th>
                        <th className="text-center bold" colSpan={3}>{t('riskManagement.details.headers.counterpartyRisk')}</th>
                        <th className="text-center bold" colSpan={4}>{t('riskManagement.details.headers.marketRisk')}</th>
                        <th className="text-center bold" colSpan={1} rowSpan={3}><span>{t('riskManagement.details.headers.rubricRisk')}</span></th>
                    </tr>
                    <tr>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.days')}</td>
                        <td colSpan={1} className="text-center bold ">{t('riskManagement.details.headers.transactions')}</td>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.holders')}</td>
                        <td colSpan={2} className="text-center bold">{t('riskManagement.details.headers.permissions')}</td>
                        <td colSpan={2} className="text-center bold">{t('riskManagement.details.headers.marketCap')}</td>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.averageDailyVolume')}</td>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.normalizedVolatility')}</td>
                    </tr>
                    <tr>
                        <td colSpan={1} className="text-center">{ data.smartContractRisk.days }</td>
                        <td colSpan={1} className="text-center">{ data.smartContractRisk.transactions }</td>
                        <td colSpan={1} className="text-center">{ data.counterpartyRisk.holders }</td>
                        <td colSpan={2} className="text-center">{ data.counterpartyRisk.Permissions }</td>
                        <td colSpan={2} className="text-center">{ `$${new Intl.NumberFormat().format(data.marketRisk.marketCap)}` }</td>
                        <td colSpan={1} className="text-center">{ `$${new Intl.NumberFormat().format(data.marketRisk.averageDailyVolume)}` }</td>
                        <td colSpan={1} className="text-center">{ data.marketRisk.normalizedVolatility }</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-center bold">{t('riskManagement.details.headers.maturity')}</td>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.centralization')}</td>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.trust')}</td>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.diversifiedAverage')}</td>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.marketCap')}</td>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.liquidity')}</td>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.volatility')}</td>
                        <td colSpan={1} className="text-center bold">{t('riskManagement.details.headers.diversifiedAverage')}</td>
                        <td colSpan={1} rowSpan={2} className="text-center bold gray">{data.marketRisk.rubrikRisk}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-center bold gray">{ data.smartContractRisk.maturityRisk }</td>
                        <td colSpan={1} className="text-center bold gray">{ data.counterpartyRisk.centralizationRisk }</td>
                        <td colSpan={1} className="text-center bold gray">{ data.counterpartyRisk.trustRisk }</td>
                        <td colSpan={1} className="text-center bold gray">{ data.counterpartyRisk.diversifiedAverageRisk }</td>
                        <td colSpan={1} className="text-center bold gray">{ data.marketRisk.marketCapRisk }</td>
                        <td colSpan={1} className="text-center bold gray">{ data.marketRisk.liquidityRisk }</td>
                        <td colSpan={1} className="text-center bold gray">{ data.marketRisk.volatilityRisk }</td>
                        <td colSpan={1} className="text-center bold gray">{ data.marketRisk.normalizedVolatilityDiversifiedAverageRisk }</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <Typography component="p" variant="body2" style={{ marginTop: 16 }}><strong>Debt Percentage: </strong>{ data.debtPercentage } %</Typography>
            <Typography component="p" variant="body2"><strong>Total Onchain Liquidity: </strong>{ `$${new Intl.NumberFormat().format(data.onchainLiquidity)}` }</Typography>
            <Typography component="p" variant="body2"><strong>Does one wallet own &gt; 20% of tokens in LPs?: </strong>{ data.oneWalletOwn20OfTokens }</Typography>
        </div>
    );
};

const RiskManagementDetails: React.FC<RiskManagementDetailsProps> = ({ chainId, tokenId }) => {
    const [detailsData, setDetailsData] = useState<IRiskManagementTokenData>();
    const { data } = useGetRiskManagementChainData();

    useEffect(() => {
        if (data?.data) {
            setDetailsData(data.data.marketsRisk[chainId][tokenId] || null);
        }
    }, [data]);

    if (!detailsData) return null;

    return (
        <RiskManagementDetailsUi data={detailsData} />
    );
};

export default RiskManagementDetails;
