/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'translation';
import { truncateAddress } from 'utilities';

import { AuthContext } from 'context/AuthContext';

import { ButtonProps, IconButton } from '../../Button';
import { useStyles } from './styles';

export interface ConnectButtonProps extends ButtonProps {
  accountAddress?: string;
}

export const ConnectButtonUi: React.FC<ConnectButtonProps> = ({
  accountAddress,
  ...otherProps
}) => {
  const { t } = useTranslation();

  return (
    <IconButton small {...otherProps}>
      {!accountAddress ? t('connectButton.title') : truncateAddress(accountAddress)}
    </IconButton>
  );
};

export const ConnectButton: React.FC<ButtonProps> = props => {
  const { account, openAuthModal } = React.useContext(AuthContext);
  const styles = useStyles();

  return (
    <ConnectButtonUi
      accountAddress={account?.address}
      onClick={openAuthModal}
      variant={account ? 'secondary' : 'primary'}
      css={styles.getButton({ connected: !!account })}
      endIcon={account ? 'disconnect' : 'arrowShaft'}
      endIconBackground={!account ? '#FFF' : undefined}
      endIconAnimation={!account}
      {...props}
    />
  );
};

export default ConnectButton;
