import BigNumber from 'bignumber.js';

import { CErc20, CBttToken } from 'types/contracts';

export interface GetCTokenCashInput {
  cTokenContract: CErc20 | CBttToken;
}

export type GetCTokenCashOutput = {
  cashWei: BigNumber;
};

const getCTokenCash = async ({
  cTokenContract,
}: GetCTokenCashInput): Promise<GetCTokenCashOutput> => {
  const res = await cTokenContract.methods.getCash().call();

  return {
    cashWei: new BigNumber(res),
  };
};

export default getCTokenCash;
