import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  const gap = theme.spacing(8);

  return {
    getRow: ({ last }: { last?: boolean }) => css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: ${!last && gap};

      ${theme.breakpoints.down('xl')} {
        flex-direction: column;
        margin-bottom: 0;
      }
    `,
    column: css`
      width: calc(50% - ${gap} / 2);

      ${theme.breakpoints.down('xl')} {
        width: 100%;
        margin-bottom: ${gap};
      }
    `,
    headerRoot: css`
      margin-bottom: ${theme.spacing(8)};
      padding: ${theme.spacing(4)} 0 ${theme.spacing(4)} ${theme.spacing(6)};

      ${theme.breakpoints.down('xxl')} {
        padding: 0;
        background-color: transparent;
        margin-bottom: ${theme.spacing(6)};
      }
    `,
  };
};
