import { MutationObserverOptions } from 'react-query';
import { CTokenId } from 'types';

import {
  // RepayBnbInput,
  // RepayBnbOutput,
  RepayNonBnbCTokenInput,
  RepayNonBnbCTokenOutput,
  // useRepayBnb,
  useRepayNonBnbCToken,
} from 'clients/api';

type Options = MutationObserverOptions<
  // RepayBnbOutput |
  RepayNonBnbCTokenOutput,
  Error,
  Omit<RepayNonBnbCTokenInput, 'cTokenContract'>
  //  | Omit<RepayBnbInput, 'web3'>
>;

const useRepayCToken = ({ cTokenId }: { cTokenId: CTokenId }, options?: Options) => {
  const useRepayNonBnbCTokenResult = useRepayNonBnbCToken(
    { cTokenId: cTokenId as Exclude<CTokenId, 'btt'> },
    options,
  );
  // ? (coz MARKET is not deployed)
  // const useRepayBnbResult = useRepayBnb(options);
  // return cTokenId === 'btt' ? useRepayBnbResult : useRepayNonBnbCTokenResult;
  return useRepayNonBnbCTokenResult;
};

export default useRepayCToken;
