import { useMemo } from 'react';
import { TokenId, CTokenId } from 'types';

import { useWeb3 } from 'clients/web3';

import {
  getComptrollerContract,
  getGovernorBravoDelegateContract,
  getInterestModelContract,
  getPriceOracleContract,
  getTokenContract,
  getTokenContractByAddress,
  getCTokenContract,
  getUnitrollerContract,
  // getVaiVaultContract,
  getVenusLensContract,
  // getVrtConverterProxyContract,
  // getVrtVaultProxyContract,
  // getXvsVaultContract,
  getBlendProxyContract,
  // getXvsVestingProxyContract,
} from './getters';

export const useTokenContract = <T extends TokenId>(name: T) => {
  const web3 = useWeb3();
  return useMemo(() => getTokenContract<T>(name, web3), [web3, name]);
};

export const useTokenContractByAddress = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getTokenContractByAddress(address, web3), [web3, address]);
};

export const useCTokenContract = <T extends CTokenId>(name: T) => {
  const web3 = useWeb3();
  return useMemo(() => getCTokenContract<T>(name, web3), [web3, name]);
};

export const useUnitrollerContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getUnitrollerContract(web3), [web3]);
};

// export const useVaiVaultContract = () => {
//   const web3 = useWeb3();
//   return useMemo(() => getVaiVaultContract(web3), [web3]);
// };

export const useComptrollerContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getComptrollerContract(web3), [web3]);
};

export const usePriceOracleContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getPriceOracleContract(web3), [web3]);
};

export const useInterestModelContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getInterestModelContract(address, web3), [web3]);
};

export const useVenusLensContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getVenusLensContract(web3), [web3]);
};

export const useBlendContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getBlendProxyContract(web3), [web3]);
};

// export const useBlendContract = () => {
//   const web3 = useWeb3();
//   return useMemo(() => getBlendProxyContract(web3), [web3]);
// };

export const useGovernorBravoDelegateContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getGovernorBravoDelegateContract(web3), [web3]);
};

// // VRT conversion
// export const useVrtConverterProxyContract = () => {
//   const web3 = useWeb3();
//   return useMemo(() => getVrtConverterProxyContract(web3), [web3]);
// };

// export const useXvsVestingProxyContract = () => {
//   const web3 = useWeb3();
//   return useMemo(() => getXvsVestingProxyContract(web3), [web3]);
// };

// export const useVrtVaultProxyContract = () => {
//   const web3 = useWeb3();
//   return useMemo(() => getVrtVaultProxyContract(web3), [web3]);
// };
