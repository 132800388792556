import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    getButton: ({ connected }: { connected: boolean }) => css`
      color: ${!connected ? 'black' : '#2BD181'};
      background-color: ${connected && 'rgba(43, 209, 129, 0.1)'};
      display: flex;
      border: ${connected && '0px'};
      border-radius: 25px;
      :hover {
        background-color: ${connected && 'rgba(43, 209, 129, 0.3)'} !important;
      }

      ${theme.breakpoints.down('md')} {
        justify-content: space-between;
      }
    `,
  };
};
