import { QueryObserverOptions, useQuery } from 'react-query';

import getCTokenBalancesAll, {
  GetCTokenBalancesAllInput,
  IGetCTokenBalancesAllOutput,
} from 'clients/api/queries/getCTokenBalancesAll';
import { useVenusLensContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  IGetCTokenBalancesAllOutput,
  Error,
  IGetCTokenBalancesAllOutput,
  IGetCTokenBalancesAllOutput,
  [FunctionKey.GET_V_TOKEN_BALANCES_ALL, Omit<GetCTokenBalancesAllInput, 'venusLensContract'>]
>;

const useGetCTokenBalancesAll = (
  { account, cTokenAddresses }: Omit<GetCTokenBalancesAllInput, 'venusLensContract'>,
  options?: Options,
) => {
  const venusLensContract = useVenusLensContract();
  return useQuery(
    [FunctionKey.GET_V_TOKEN_BALANCES_ALL, { account, cTokenAddresses }],
    () => getCTokenBalancesAll({ venusLensContract, account, cTokenAddresses }),
    options,
  );
};

export default useGetCTokenBalancesAll;
