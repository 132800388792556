import { TokenId } from 'types';

import cErcTokens from 'constants/contracts/addresses/cErcTokens.json';

const getTokenIdFromVAddress = (address: string) => {
  const token = Object.entries<{ [key: string]: string }>(cErcTokens).find(
    ([, value]) =>
      value[process.env.REACT_APP_CHAIN_ID || '199'].toLowerCase() === address.toLowerCase(),
  );
  if (token) {
    return token[0] as TokenId;
  }
};

export default getTokenIdFromVAddress;
