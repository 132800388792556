import * as React from 'react';
import { SVGProps } from 'react';

const SvgVault = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0833 0.500015H2.91667C2.1434 0.500923 1.40206 0.808505 0.855275 1.35529C0.30849 1.90207 0.000907764 2.64341 0 3.41668V11.5833C0.000907764 12.3566 0.30849 13.098 0.855275 13.6447C1.40206 14.1915 2.1434 14.4991 2.91667 14.5H11.0833C11.8566 14.4991 12.5979 14.1915 13.1447 13.6447C13.6915 13.098 13.9991 12.3566 14 11.5833V3.41668C13.9991 2.64341 13.6915 1.90207 13.1447 1.35529C12.5979 0.808505 11.8566 0.500923 11.0833 0.500015ZM12.8333 11.5833C12.8328 12.0473 12.6483 12.4921 12.3202 12.8202C11.9921 13.1483 11.5473 13.3328 11.0833 13.3333H2.91667C2.45269 13.3328 2.00787 13.1483 1.67979 12.8202C1.35171 12.4921 1.16717 12.0473 1.16667 11.5833V3.41668C1.16717 2.95271 1.35171 2.50788 1.67979 2.1798C2.00787 1.85173 2.45269 1.66719 2.91667 1.66668H11.0833C11.5473 1.66719 11.9921 1.85173 12.3202 2.1798C12.6483 2.50788 12.8328 2.95271 12.8333 3.41668V11.5833Z"
      fill="white"
    />
    <path
      d="M7 3.5C6.20888 3.5 5.43552 3.7346 4.77772 4.17412C4.11992 4.61365 3.60723 5.23836 3.30448 5.96927C3.00173 6.70017 2.92252 7.50444 3.07686 8.28036C3.2312 9.05629 3.61216 9.76902 4.17157 10.3284C4.73098 10.8878 5.44372 11.2688 6.21964 11.4231C6.99556 11.5775 7.79983 11.4983 8.53074 11.1955C9.26164 10.8928 9.88635 10.3801 10.3259 9.72228C10.7654 9.06448 11 8.29113 11 7.5C10.9988 6.43951 10.577 5.4228 9.82709 4.67291C9.07721 3.92303 8.0605 3.50121 7 3.5V3.5ZM7 10.5C6.40666 10.5 5.82664 10.3241 5.33329 9.99441C4.83994 9.66476 4.45543 9.19623 4.22836 8.64805C4.0013 8.09987 3.94189 7.49667 4.05765 6.91473C4.1734 6.33279 4.45912 5.79824 4.87868 5.37868C5.29824 4.95912 5.83279 4.6734 6.41473 4.55764C6.99667 4.44189 7.59987 4.5013 8.14805 4.72836C8.69623 4.95542 9.16477 5.33994 9.49441 5.83329C9.82405 6.32664 10 6.90666 10 7.5C9.99913 8.29538 9.68278 9.05794 9.12036 9.62036C8.55794 10.1828 7.79538 10.4991 7 10.5V10.5Z"
      fill="white"
    />
    <path
      d="M8.50003 7H7.8609C7.75083 6.80936 7.58093 6.66036 7.37756 6.57612C7.17418 6.49188 6.94869 6.4771 6.73605 6.53407C6.52342 6.59105 6.33553 6.71659 6.20152 6.89124C6.06751 7.06588 5.99487 7.27987 5.99487 7.5C5.99487 7.72013 6.06751 7.93412 6.20152 8.10876C6.33553 8.28341 6.52342 8.40895 6.73605 8.46593C6.94869 8.5229 7.17418 8.50812 7.37756 8.42388C7.58093 8.33964 7.75083 8.19064 7.8609 8H8.50003C8.63264 8 8.75981 7.94732 8.85358 7.85355C8.94735 7.75979 9.00003 7.63261 9.00003 7.5C9.00003 7.36739 8.94735 7.24022 8.85358 7.14645C8.75981 7.05268 8.63264 7 8.50003 7Z"
      fill="white"
    />
  </svg>
);

export default SvgVault;
