import { set } from 'lodash';
import { BITLEND_API_URL } from 'constants/endpoints';
import { IRiskManagementChainData, IRiskManagementTokenData, RiskManagementChains } from './types';

export interface GetRiskManagementDataChainOutput {
    status: number | boolean;
    data?: {
        marketsSnapshot: Record<RiskManagementChains, IRiskManagementChainData[]>;
        marketsRisk: Record<RiskManagementChains, Record<string, IRiskManagementTokenData>>;
    };
    result?: string;
    message?: string;
}

export interface GetRiskManagementDataTokenOutput {
    status: number | boolean;
    data?: IRiskManagementTokenData;
    result?: string;
    message?: string;
}

const getRiskManagementTokenData = async (chain: string, token: string): Promise<GetRiskManagementDataTokenOutput> => {
    let path: string;
    const headers = {};

    set(headers, 'Accept', 'application/json');
    set(headers, 'Content-Type', 'application/json');

    if (chain && token) {
        path = `${BITLEND_API_URL}/api/v1/market-stats/${token}/${chain}`;
    } else {
        throw new Error('Must specify a chain and a token');
    }

    return fetch(path, { headers })
        .then(response =>
            response
                .json()
                .then(json =>
                    json
                        ? { status: response.status, data: json.data.result }
                        : { status: response.status, data: undefined },
                ),
        )
        .catch(error => ({
            status: false,
            data: undefined,
            result: 'error',
            message: error,
        }));
};

const getRiskManagementChainData = async (): Promise<GetRiskManagementDataChainOutput> => {
    let path: string;
    const headers = {};

    set(headers, 'Accept', 'application/json');
    set(headers, 'Content-Type', 'application/json');

    return fetch(`${BITLEND_API_URL}/api/v1/market-stats/all`, { headers })
        .then(response =>
            response
                .json()
                .then(json =>
                    json
                        ? { status: response.status, data: json.data.result }
                        : { status: response.status, data: undefined },
                ),
        )
        .catch(error => ({
            status: false,
            data: undefined,
            result: 'error',
            message: error,
        }));
};

export {
    getRiskManagementTokenData,
    getRiskManagementChainData,
};
