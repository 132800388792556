import * as React from 'react';
import { SVGProps } from 'react';

const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="14" height="14" rx="7" fill="#242424" />
    <path d="M4 5.5L7 8.5L10 5.5" stroke="#858585" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default SvgChevronDown;
