import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { RiskManagementChains } from 'clients/api/queries/getRiskManagementData/types';
import RiskManagementTable from './RiskManagementTable';
import RiskManagementDetails from './RiskManagementDetails';

type RiskManagementDetailsProps = {
    chainId: RiskManagementChains;
    tokenId: string;
};

export type RiskManagementProps = RouteComponentProps<RiskManagementDetailsProps>;

const RiskManagementUi: React.FC = () => <RiskManagementTable />;

const RiskManagementDetailsUi: React.FC<RiskManagementDetailsProps> = ({ chainId, tokenId }) => <RiskManagementDetails chainId={chainId} tokenId={tokenId} />;

const RiskManagement: React.FC<RiskManagementProps> = ({
    match: {
        params: { tokenId, chainId },
    },
}) => {
    if (tokenId && chainId) {
        return <RiskManagementDetailsUi chainId={chainId} tokenId={tokenId} />;
    }

    return <RiskManagementUi />;
};

export default RiskManagement;
