/** @jsxImportSource @emotion/react */
import { css } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import config from 'config';
import React, { useState } from 'react';
import { useTranslation } from 'translation';

import { ReactComponent as LogoDesktop } from 'assets/img/bitlendLogoDesktop.svg';
import { ReactComponent as LogoNoText } from 'assets/img/bitlendLogoMobile.svg';
import { useGetBlockNumber } from 'clients/api';

import { Icon } from '../../Icon';
// import ClaimXvsRewardButton from '../ClaimXvsRewardButton';
import ConnectButton from '../ConnectButton';
import { Toolbar } from '../Toolbar';
import { menuItems } from '../constants';
import Link from './Link';
import { BITLEND_TELEGRAM_LINK, BITLEND_TWITTER_URL } from './constants';
import { useStyles } from './styles';

export const SidebarUi: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const styles = useStyles();
  const { data: currentBlockNumber } = useGetBlockNumber();

  const openMenu = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* Desktop and tablet menu */}
      <Drawer variant="permanent" css={styles.drawer}>
        <div css={styles.drawerContainer}>
          <div css={styles.drawerContent}>
            <Toolbar css={styles.toolbar}>
              <LogoDesktop css={styles.logo} />
              <LogoNoText css={styles.logoClosed} />
            </Toolbar>
            <div css={styles.divider} />
            <List>
              <Typography css={styles.categoryLabel} variant="body1">
                GENERAL
              </Typography>
              {menuItems.map(menuItem => (
                <ListItemButton
                  key={menuItem.i18nKey}
                  component="li"
                  css={styles.listItem}
                  disableRipple
                >
                  <Link href={menuItem.href}>
                    <ListItemIcon css={styles.listItemIcon}>
                      <Icon
                        name={menuItem.icon}
                        css={menuItem.soon && styles.disabledListItemText}
                      />
                    </ListItemIcon>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <Typography
                        variant="body2"
                        css={[styles.listItemText, menuItem.soon && styles.disabledListItemText]}
                      >
                        {t(menuItem.i18nKey)}
                      </Typography>
                      {menuItem.soon && (
                        <Typography className="soonLabel" component="span" variant="tiny">
                          SOON
                        </Typography>
                      )}
                    </div>
                    <div className="left-border" />
                  </Link>
                </ListItemButton>
              ))}
            </List>
          </div>
          {!!currentBlockNumber && (
            <div>
              <div css={styles.divider} />
              <div
                css={css`
                  margin: 0px 20px 20px 20px;
                  padding: 20px;
                `}
              >
                <Typography
                  component="a"
                  variant="small2"
                  css={styles.blockInfo}
                  href={config.bscScanUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('footer.latestNumber').toUpperCase()}
                  <br />
                  <span css={styles.blockInfoNumber}>#{currentBlockNumber.blockNumber}</span>
                </Typography>
                <div css={styles.links}>
                  <a
                    css={styles.link}
                    href={BITLEND_TELEGRAM_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon name="telegram" color={styles.theme.palette.text.primary} size="12px" />
                  </a>
                  <a css={styles.link} href={BITLEND_TWITTER_URL} target="_blank" rel="noreferrer">
                    <Icon name="twitter" color={styles.theme.palette.text.primary} size="12px" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </Drawer>

      {/* Mobile menu */}
      <div css={styles.mobileMenuBox}>
        <div css={styles.flexRow}>
          <Icon name="logoMobile" css={styles.mobileLogo} />

          <ConnectButton small fullWidth css={styles.mobileConnectButton} />

          <button type="button" onClick={openMenu} css={styles.actionButton}>
            <Icon name="burger" css={styles.burger} />
          </button>
        </div>

        <Menu
          css={styles.mobileMenu}
          className="mobile-menu"
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={closeMenu}
          transitionDuration={0}
          marginThreshold={0}
          TransitionProps={{ style: { transition: 'background 0.2s linear' } }}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 0, left: 0 }}
        >
          <div>
            <div css={[styles.flexRow, styles.doublePadding]}>
              <Icon name="logoMobile" css={styles.mobileLogo} />

              <ConnectButton small fullWidth css={styles.mobileConnectButton} />

              <button type="button" onClick={closeMenu} css={styles.actionButton}>
                <Icon name="close" css={styles.burger} />
              </button>
            </div>

            <List>
              {menuItems.map(({ href, icon, i18nKey, soon }) => (
                <ListItemButton
                  key={i18nKey}
                  component="li"
                  css={[styles.listItem, styles.mobileListItem]}
                  disableRipple
                >
                  <Link onClick={closeMenu} href={href}>
                    <div css={styles.mobileLabel}>
                      <ListItemIcon css={styles.listItemIcon}>
                        <Icon name={icon} />
                      </ListItemIcon>

                      <Typography
                        variant="body2"
                        component="span"
                        css={[
                          styles.listItemText,
                          styles.mobileListItemText,
                          soon && styles.disabledListItemText,
                        ]}
                      >
                        {t(i18nKey)}
                      </Typography>
                      {soon && (
                        <Typography className="soonLabel" component="span" variant="tiny">
                          SOON
                        </Typography>
                      )}
                    </div>

                    {/* <Icon name="arrowRight" css={styles.mobileArrow} /> */}
                  </Link>
                </ListItemButton>
              ))}
            </List>
          </div>
          {!!currentBlockNumber && (
            <div>
              <div css={styles.divider} />
              <div
                css={css`
                  margin: 20px 40px;
                `}
              >
                <Typography
                  component="a"
                  variant="small2"
                  css={styles.blockInfo}
                  href={config.bscScanUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('footer.latestNumber').toUpperCase()}
                  <br />
                  <span css={styles.blockInfoNumber}>#{currentBlockNumber.blockNumber}</span>
                </Typography>
                <div css={styles.links}>
                  <a
                    css={styles.link}
                    href={BITLEND_TELEGRAM_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon name="telegram" color={styles.theme.palette.text.primary} size="12px" />
                  </a>
                  <a css={styles.link} href={BITLEND_TWITTER_URL} target="_blank" rel="noreferrer">
                    <Icon name="twitter" color={styles.theme.palette.text.primary} size="12px" />
                  </a>
                </div>
              </div>
            </div>
          )}

          {/* <ClaimXvsRewardButton css={styles.claimXvsRewardButton} /> */}
        </Menu>
      </div>
    </>
  );
};

export default SidebarUi;
