import { Table, TableProps, TableRowProps } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'translation';
import { formatCentsToReadableValue } from 'utilities';

import {
  IRiskManagementChainData,
  RiskManagementChains,
} from 'clients/api/queries/getRiskManagementData/types';
import { useGetRiskManagementChainData } from 'clients/api/queries/getRiskManagementData/useGetRiskManagementData';
import Path from 'constants/path';

type RiskManagementTableUiProps = {
  chainData: Array<IRiskManagementChainData>;
};

const RiskManagementTableUi: React.FC<RiskManagementTableUiProps> = ({ chainData }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement>, row: TableRowProps[]) => {
    const chain = row.filter(item => item.key === 'chain')[0].value;
    const token = row.filter(item => item.key === 'symbol')[0].value;

    if (chain && token) {
      history.push(
        `${Path.RISK_MANAGEMENT}/${String(chain).toLowerCase()}/${String(token).toLowerCase()}`,
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        key: 'token',
        label: t('riskManagement.columns.token'),
        orderable: true,
        align: 'left',
      },
      {
        key: 'chain',
        label: t('riskManagement.columns.chain'),
        orderable: true,
        align: 'left',
      },
      {
        key: 'marketCap',
        label: t('riskManagement.columns.marketCap'),
        orderable: true,
        align: 'left',
      },
      {
        key: 'onchainLiquidity',
        label: t('riskManagement.columns.onchainLiquidity'),
        orderable: true,
        align: 'left',
      },
      {
        key: 'averageDailyVolume',
        label: t('riskManagement.columns.averageDailyVolume'),
        orderable: true,
        align: 'left',
      },
      {
        key: 'debtPercentage',
        label: t('riskManagement.columns.debtPercentage'),
        orderable: true,
        align: 'left',
      },
      {
        key: 'availability',
        label: t('riskManagement.columns.availability'),
        orderable: true,
        align: 'left',
      },
      {
        key: 'RiskGrading',
        label: t('riskManagement.columns.riskGrading'),
        orderable: true,
        align: 'left',
      },
    ],
    [chainData],
  );

  const rows: TableProps['data'] = useMemo(
    () =>
      chainData.map(chain => [
        {
          key: 'symbol',
          render: () => <div>{chain.name.charAt(0).toUpperCase() + chain.name.slice(1)}</div>,
          value: chain.symbol,
        },
        {
          key: 'chain',
          render: () => <div>{chain.chain.charAt(0).toUpperCase() + chain.chain.slice(1)}</div>,
          value: chain.chain,
        },
        {
          key: 'marketCap',
          render: () => (
            <div>
              {formatCentsToReadableValue({ value: chain.marketCap * 100, shortenLargeValue: true })}
            </div>
          ),
          value: chain.marketCap,
        },
        {
          key: 'onchainLiquidity',
          render: () => (
            <div>
              {formatCentsToReadableValue({
                value: chain.onchainLiquidity * 100,
                shortenLargeValue: true,
              })}
            </div>
          ),
          value: chain.onchainLiquidity,
        },
        {
          key: 'averageDailyVolume',
          render: () => (
            <div>
              {formatCentsToReadableValue({
                value: chain.averageDailyVolume * 100,
                shortenLargeValue: true,
              })}
            </div>
          ),
          value: chain.averageDailyVolume,
        },
        {
          key: 'debtPercentage',
          render: () => <div>{chain.debtPercentage}%</div>,
          value: chain.debtPercentage,
        },
        {
          key: 'availability',
          render: () => <div>{chain.availability ? 'Available' : 'Not Available'}</div>,
          value: chain.availability,
        },
        {
          key: 'RiskGrading',
          render: () => <div>{chain.RiskGrading}</div>,
          value: chain.RiskGrading,
        },
      ]),
    [chainData],
  );

  return (
    <Table columns={columns} data={rows} rowKeyIndex={0} rowOnClick={handleRowClick} withoutCards />
  );
};

const RiskManagementTable: React.FC = () => {
  const [chainData, setChainData] =
    useState<Record<RiskManagementChains, IRiskManagementChainData[]>>();

  const { data } = useGetRiskManagementChainData();

  useEffect(() => {
    if (data?.data) {
      setChainData(data.data.marketsSnapshot);
    }
  }, [data]);

  return (
    <>
      {chainData ? (
        <RiskManagementTableUi
          chainData={Object.values(chainData).flat()}
        />
      ) : null}
    </>
  );
};

export default RiskManagementTable;
