import * as React from 'react';
import { SVGProps } from 'react';

const SvgLogoMobile = (props: SVGProps<SVGSVGElement>) => {
  const { id = 'logoMobile' } = props;
  const gradientId = `gradient_${id}`;
  return (
    <svg width="100" viewBox="0 0 70 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_280_1841)">
        <path
          d="M21.8519 8.1325V5.51226C21.8517 4.93836 21.7277 4.37122 21.4881 3.84935C21.2485 3.32749 20.8991 2.86312 20.4635 2.4878L18.8222 1.06117C18.0889 0.443085 17.1585 0.105865 16.1981 0.110086H2.17565C1.59863 0.110086 1.04524 0.338546 0.637229 0.745215C0.229216 1.15188 0 1.70345 0 2.27856V7.22421C0.00125817 7.36717 0.05912 7.50384 0.160987 7.60448C0.262854 7.70512 0.40048 7.76158 0.543912 7.76158H2.51917C2.6626 7.76283 2.79972 7.8205 2.9007 7.92203C3.00167 8.02357 3.05832 8.16074 3.05831 8.3037V10.2677C3.05707 10.4098 2.99986 10.5458 2.89902 10.6463C2.79818 10.7468 2.66177 10.8038 2.51917 10.8051H0.543912C0.40048 10.805 0.262854 10.8615 0.160987 10.9622C0.05912 11.0628 0.00125817 11.1995 0 11.3424V18.4518H19.6953C21.0817 18.4672 22.4223 17.958 23.4468 17.0269C24.4712 16.0958 25.1033 14.8121 25.2156 13.4348C25.2849 12.3108 24.9972 11.1937 24.3934 10.2419C23.7896 9.29009 22.9004 8.55204 21.8519 8.1325ZM3.07263 3.16783H16.2029C16.4275 3.16754 16.6445 3.24867 16.8136 3.39609L18.4548 4.82272C18.5569 4.91088 18.639 5.01963 18.6958 5.14177C18.7525 5.26392 18.7826 5.39668 18.784 5.53128V7.76633H3.61177C3.46834 7.76508 3.33122 7.70741 3.23024 7.60588C3.12927 7.50435 3.07262 7.36717 3.07263 7.22421V3.16783ZM19.7717 15.3845H3.07263V11.3472C3.07262 11.2042 3.12927 11.067 3.23024 10.9655C3.33122 10.864 3.46834 10.8063 3.61177 10.8051H19.8337C20.1548 10.8055 20.4722 10.8729 20.7656 11.0029C21.0589 11.133 21.3217 11.3229 21.537 11.5603C21.7522 11.7977 21.9153 12.0774 22.0155 12.3814C22.1158 12.6854 22.1511 13.007 22.1191 13.3254C22.0418 13.8953 21.7599 14.4179 21.3257 14.7965C20.8914 15.1751 20.3343 15.384 19.7574 15.3845H19.7717Z"
          fill="white"
        />
        <path
          d="M63.0654 0.604391H52.3255C52.2543 0.603763 52.1837 0.617199 52.1177 0.643923C52.0517 0.670647 51.9917 0.710134 51.9412 0.760095C51.8906 0.810057 51.8505 0.869506 51.8231 0.935013C51.7957 1.00052 51.7816 1.07079 51.7816 1.14176V2.56839C51.7818 2.64473 51.7583 2.71927 51.7144 2.78182C51.6704 2.84437 51.6082 2.89188 51.5362 2.91789C51.4642 2.9439 51.3859 2.94712 51.312 2.92713C51.238 2.90713 51.1721 2.86489 51.1232 2.80616L50.4123 1.94543C50.0723 1.53611 49.6459 1.20666 49.1634 0.980597C48.6809 0.754537 48.1543 0.637443 47.6211 0.637683H38.8184V3.69542H47.6068C47.6872 3.69635 47.7663 3.71469 47.8388 3.74919C47.9113 3.78368 47.9754 3.83348 48.0267 3.89515L49.6632 5.87341C49.7447 5.97073 49.7887 6.09382 49.7872 6.22056V19.0602H52.8599V6.20154C52.8608 5.36277 52.5671 4.55013 52.0297 3.90466C52.0111 3.88104 51.9994 3.85272 51.996 3.82287C51.9927 3.79302 51.9977 3.76282 52.0106 3.73566C52.0235 3.7085 52.0437 3.68546 52.069 3.66911C52.0942 3.65277 52.1236 3.64376 52.1537 3.64311H63.0463L63.0654 0.604391Z"
          fill="white"
        />
        <path
          d="M34.8056 19.0412H31.7377V3.66212H31.4133L28.2786 5.21714L26.9141 2.47801L30.3207 0.785079C30.5642 0.66604 30.8319 0.60422 31.1031 0.60437H33.1452C33.5855 0.60437 34.0079 0.778726 34.3192 1.08908C34.6306 1.39943 34.8056 1.82036 34.8056 2.25927V19.0412Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_280_1841">
          <rect width="63" height="18.9457" fill="white" transform="translate(0 0.105072)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgLogoMobile;
