enum Path {
  ROOT = '/',
  XVS = '/blend',
  MARKETS = '/markets',
  MARKET_DETAILS = '/market/:cTokenId',
  HISTORY = '/history',
  VAULTS = '/vaults',
  GOVERNANCE = '/governance',
  GOVERNANCE_LEADER_BOARD = '/governance/leaderboard',
  GOVERNANCE_PROPOSAL_DETAILS = '/governance/proposal/:id',
  GOVERNANCE_ADDRESS = '/governance/address/:address',
  RISK_MANAGEMENT = '/risk-management',
  RISK_MANAGEMENT_DETAILS = '/risk-management/:chainId/:tokenId',
  CONVERT_VRT = '/convert-vrt',
}

export default Path;
