import { t } from 'translation';

import { ReactComponent as BinanceChainWalletLogo } from 'assets/img/wallets/binanceChainWalletLogo.svg';
import { ReactComponent as BraveWalletLogo } from 'assets/img/wallets/braveWalletLogo.svg';
import { ReactComponent as CoinbaseWalletLogo } from 'assets/img/wallets/coinbaseWalletLogo.svg';
import { ReactComponent as InfinityWalletLogo } from 'assets/img/wallets/infinityWalletLogo.svg';
import { ReactComponent as LedgerLogo } from 'assets/img/wallets/ledgerLogo.svg';
import { ReactComponent as MetaMaskLogo } from 'assets/img/wallets/metaMaskLogo.svg';
import { ReactComponent as OperaWalletLogo } from 'assets/img/wallets/operaWalletLogo.svg';
import { ReactComponent as SafePalLogo } from 'assets/img/wallets/safePalLogo.svg';
import { ReactComponent as TrustWalletLogo } from 'assets/img/wallets/trustWalletLogo.svg';
import { ReactComponent as WalletConnectLogo } from 'assets/img/wallets/walletConnectLogo.svg';
import { Connector } from 'clients/web3';
import { isRunningInOperaBrowser } from 'clients/web3/walletDetectionUtils';

import { BaseWallet, IntegratedWallet, Wallet } from './types';

export const VENUS_TERMS_OF_SERVICE_URL = 'https://www.swipe.io/terms';

export const WALLETS: Wallet[] = [
  {
    name: t('wallets.metamask'),
    Logo: MetaMaskLogo,
    connector: Connector.MetaMask,
  },
  // {
  //   name: t('wallets.coinbaseWallet'),
  //   Logo: CoinbaseWalletLogo,
  //   connector: Connector.CoinbaseWallet,
  //   mainnetOnly: true,
  // },
  // {
  //   name: t('wallets.trustWallet'),
  //   Logo: TrustWalletLogo,
  //   connector: Connector.TrustWallet,
  // },
  {
    name: t('wallets.walletConnect'),
    Logo: WalletConnectLogo,
    connector: Connector.WalletConnect,
  },
  // {
  //   name: t('wallets.binanceChainWallet'),
  //   Logo: BinanceChainWalletLogo,
  //   connector: Connector.BinanceChainWallet,
  // },
  // {
  //   name: t('wallets.braveWallet'),
  //   Logo: BraveWalletLogo,
  //   connector: Connector.BraveWallet,
  // },
  // {
  //   name: t('wallets.infinityWallet'),
  //   Logo: InfinityWalletLogo,
  //   connector: Connector.InfinityWallet,
  //   mainnetOnly: true,
  // },
];

// Add Opera Wallet to the top of the list if dApp is running in Opera
if (isRunningInOperaBrowser()) {
  WALLETS.unshift({
    name: t('wallets.opera'),
    Logo: OperaWalletLogo,
    connector: Connector.OperaWallet,
  });
}

export const INTEGRATED_WALLETS: IntegratedWallet[] = [
  // {
  //   name: t('wallets.ledger'),
  //   Logo: LedgerLogo,
  //   linkUrl: 'https://www.ledger.com/academy/security/the-safest-way-to-use-metamask',
  // },
];

export const UPCOMING_WALLETS: BaseWallet[] = [
  // {
  //   name: 'SafePal',
  //   Logo: SafePalLogo,
  // },
];
