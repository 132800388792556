import { QueryObserverOptions, useQuery } from 'react-query';
import { getRiskManagementChainData, getRiskManagementTokenData } from 'clients/api/queries/getRiskManagementData';
import FunctionKey from 'constants/functionKey';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import { GetRiskManagementDataChainOutput, GetRiskManagementDataTokenOutput } from './index';

type ChainOptions = QueryObserverOptions<
    GetRiskManagementDataChainOutput,
    Error,
    GetRiskManagementDataChainOutput,
    GetRiskManagementDataChainOutput,
    FunctionKey.GET_CHAIN_RISK_MANAGEMENT_DATA
>;

type TokenOptions = QueryObserverOptions<
    GetRiskManagementDataTokenOutput,
    Error,
    GetRiskManagementDataTokenOutput,
    GetRiskManagementDataTokenOutput,
    FunctionKey.GET_TOKEN_RISK_MANAGEMENT_DATA
>;

export const useGetRiskManagementChainData = (options?: ChainOptions) => useQuery(FunctionKey.GET_CHAIN_RISK_MANAGEMENT_DATA, getRiskManagementChainData, {
        ...options,
    });


//! DEPRECATED ENDPOINT: to be removed along with its unused utils
export const useGetRiskManagementTokenData = (chain: string, token: string, options?: TokenOptions) => useQuery(FunctionKey.GET_TOKEN_RISK_MANAGEMENT_DATA, () => getRiskManagementTokenData(chain, token), {
    ...options,
});
