import { BscConnector } from '@binance-chain/bsc-connector';
import { InfinityWalletConnector } from '@infinitywallet/infinity-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import config from 'config';
import { BttcChainId } from 'types';

import { Connector } from './types';

export const injectedConnector = new InjectedConnector({ supportedChainIds: [config.chainId] });

const walletConnectConnector = new WalletConnectConnector({
  rpc: { [config.chainId]: config.rpcUrl },
  chainId: Number(config.chainId),
  bridge: 'https://bridge.walletconnect.org',
  supportedChainIds: [Number(config.chainId)],
  qrcodeModalOptions: {
    registryUrl: '',
    mobileLinks: [],
    desktopLinks: [],
  },
  qrcode: true,
  clientMeta: {
    name: 'Bitlend',
    description: 'Bitlend is a permissionless, transparent, and non-custodial lending platform native to BitTorrent Chain. A fork of Compound Finance v2, it will be the first lending and borrowing protocol to launch on BTTC - paving the way for fast and scalable DeFi in the BTTC ecosystem. Bitlend’s entire codebase is comprised of Solidity, and will utilize Band price oracles in lieu of Chainlink.',
    url: 'https://alpha.testnet.app.bitlend.fi/',
    icons: ['https://www.gitbook.com/cdn-cgi/image/width=40,height=40,fit=contain,dpr=1.1399999856948853,format=auto/https%3A%2F%2F2215684476-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252Fatbm18m6to67Yp2iLjed%252Ficon%252FRpJVQLaUbtCQHDNxXDmP%252FF9A3EFED-4E9B-4D51-9DF5-9FCFB2E75FA5.jpeg%3Falt%3Dmedia%26token%3Dd3fa30df-817d-4a70-8701-598f55649984'],
  },
});

const binanceChainWalletConnector = new BscConnector({ supportedChainIds: [config.chainId] });

const coinbaseWalletConnector = new WalletLinkConnector({
  url: config.rpcUrl,
  appName: 'Bitlend',
});

const infinityWalletConnector = new InfinityWalletConnector({
  supportedChainIds: [BttcChainId.MAINNET],
});

export const connectorsByName = {
  [Connector.MetaMask]: injectedConnector,
  [Connector.BraveWallet]: injectedConnector,
  [Connector.WalletConnect]: walletConnectConnector,
  [Connector.CoinbaseWallet]: coinbaseWalletConnector,
  [Connector.TrustWallet]: injectedConnector,
  [Connector.BinanceChainWallet]: binanceChainWalletConnector,
  [Connector.InfinityWallet]: infinityWalletConnector,
  [Connector.OperaWallet]: injectedConnector,
};
